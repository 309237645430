var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import LazyLoad from "react-lazy-load";
import { HashLink } from "react-router-hash-link";
import { Paper, Typography, Grid, Button } from "@mui/material";
import Box from "@mui/system/Box";
import ImageBanner from "Layout/ImageBanner";
import Icon from "cms/editableComponents/Icon";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchIcon from "@mui/icons-material/Search";
import TabPanel from "components/TabPanel";
import EstablishmentCard from "components/EstablishmentCard";
import GoogleMapEstablishments from "components/GoogleMapEstablishments";
import { useEstablishmentList } from "services/establishments.service";
import { useEnhanceListWithPageVersions } from "services/pageversion.service";
import NewsLetter from "projects/ARPAVIE/components/NewsLetter";
var PageCard = function (_a) {
    var page = _a.page;
    var title = page.title, shortDescription = page.shortDescription;
    return (_jsxs(Paper, { square: true, sx: {
            mb: 4.25,
            borderRadius: 1,
            pl: 2.5,
            pr: 10,
            py: 2.625,
        }, children: [_jsx(Typography, { variant: "h1", children: title }), _jsx(Box, { sx: {
                    fontWeight: 500,
                    fontVariant: "small-caps",
                    "& .Da-Text": { color: "colors.gris_2" },
                }, children: shortDescription })] }));
};
var HomeTemplate = function (props) {
    var page = props.page;
    var contents = page.contents;
    var theme = useTheme();
    var dividerMobile = useMediaQuery(theme.breakpoints.down("md"));
    var isUpSm = useMediaQuery(theme.breakpoints.up("sm"));
    var presentationTitle = contents.presentationTitle, presentationVideo = contents.presentationVideo, presentation = contents.presentation, speechCard = contents.speechCard, testimony = contents.testimony, servicesTitle = contents.servicesTitle, servicesSubtitle = contents.servicesSubtitle, firstBlockFeatures = contents.firstBlockFeatures, secondBlockFeatures = contents.secondBlockFeatures, thirdBlockFeatures = contents.thirdBlockFeatures, forthBlockFeatures = contents.forthBlockFeatures, customTabs = contents.customTabs, groupPresentation = contents.groupPresentation, establishmentsTitle = contents.establishmentsTitle, establishmentsSubtitle = contents.establishmentsSubtitle, financialHelp = contents.financialHelp, googleMapEstablishmentsTitle = contents.googleMapEstablishmentsTitle;
    var _a = React.useState(0), tabsIndex = _a[0], setTabsIndex = _a[1];
    React.useEffect(function () {
        if (!isUpSm)
            setTabsIndex(0);
    }, [isUpSm]);
    var VIDEO_LABEL = "Qui sommes-nous ?";
    var PRESENTATION_LABEL = "Un site fait pour vous";
    var establishmentsPagination = useEstablishmentList().data;
    var _b = (establishmentsPagination || {}).data, establishmentsResults = _b === void 0 ? [] : _b;
    var _c = useEnhanceListWithPageVersions(establishmentsResults).data, establishments = _c === void 0 ? [] : _c;
    var establishmentsForHighlightedListing = establishments.filter(function (e) { return e.isHighlighted; }).slice(0, 8);
    var handleChangeTabsIndex = function (e, newValue) {
        setTabsIndex(newValue);
    };
    function a11yProps(label) {
        return {
            id: "tab-".concat(label),
            "aria-labelledby": "tab-".concat(label),
            "aria-controls": "tabpanel-".concat(label),
        };
    }
    return (_jsxs(_Fragment, { children: [_jsx(ImageBanner, { page: page }), _jsx(Box, { sx: {
                    display: { xs: "none", lg: "flex" },
                    justifyContent: "center",
                    mt: 6.375,
                    mb: 5,
                    fontSize: "2.857rem",
                }, children: _jsx(Icon, { icon: "arrow-down", color: "colors.cyan" }) }), _jsxs(Grid, { container: true, sx: { px: 3.875 }, children: [_jsx(Grid, { item: true, xs: 12, sx: { display: { xs: "block", lg: "none" } }, children: _jsx(PageCard, { page: page }) }), _jsx(Grid, { item: true, xs: 12, lg: 8, sx: { pr: { lg: 2.875 }, mb: { xs: 4.25, lg: 0 } }, children: _jsxs(Paper, { square: true, sx: {
                                height: "100%",
                                borderRadius: 1,
                                pl: 3.5,
                                py: 2.5,
                                pr: 5.375,
                            }, children: [_jsxs(Grid, { container: true, columnSpacing: 4, rowSpacing: 2, sx: {
                                        flexDirection: { xs: "column", md: "row" },
                                        justifyContent: { xs: "flex-start", md: "space-between" },
                                        alignItems: { xs: "flex-start", md: "center" },
                                    }, children: [_jsx(Grid, { item: true, children: _jsx(Typography, { component: "h2", variant: "h3", sx: { color: "colors.bleu_fonce" }, children: presentationTitle }) }), _jsx(Grid, { item: true, children: _jsxs(Tabs, { "aria-labelledby": "presentation tabs", variant: "scrollable", orientation: dividerMobile ? "vertical" : "horizontal", allowScrollButtonsMobile: true, value: tabsIndex, onChange: handleChangeTabsIndex, children: [isUpSm && _jsx(Tab, __assign({ label: VIDEO_LABEL }, a11yProps(VIDEO_LABEL))), _jsx(Tab, __assign({ label: PRESENTATION_LABEL }, a11yProps(PRESENTATION_LABEL)))] }) })] }), isUpSm && (_jsx(TabPanel, { value: tabsIndex, index: 0, "aria-labelledby": "tabpanel-".concat(VIDEO_LABEL), label: VIDEO_LABEL, children: _jsx(LazyLoad, { children: _jsx(Box, { sx: {
                                                mt: 7.25,
                                                mb: 4.375,
                                                "& iframe": { height: { xs: "400px", md: "600px" } },
                                            }, children: presentationVideo }) }) })), _jsx(TabPanel, { value: tabsIndex, index: isUpSm ? 1 : 0, "aria-labelledby": "tabpanel-".concat(PRESENTATION_LABEL), label: PRESENTATION_LABEL, children: _jsx(Box, { sx: { mt: 7.25, mb: 4.375, fontSize: "1.429rem" }, children: presentation }) })] }) }), _jsxs(Grid, { item: true, xs: 12, lg: 4, children: [_jsx(Box, { sx: { display: { xs: "none", lg: "block" } }, children: _jsx(PageCard, { page: page }) }), speechCard] })] }), _jsxs(Box, { children: [_jsx(Box, { sx: { my: { xs: 11, md: 13, lg: 15.5 } }, children: testimony }), _jsx(Box, { sx: { mb: { xs: 11, md: 13, lg: 15.5 }, mx: { xs: 1, md: 3, lg: 3 } }, children: _jsx(NewsLetter, { isBanner: true }) }), _jsxs(Box, { sx: { textAlign: "center", px: 4, mb: { xs: 4, md: 5.75 } }, children: [_jsx(Typography, { variant: "h2", sx: { my: 1.75 }, children: servicesTitle }), _jsx(Typography, { variant: "h3", children: servicesSubtitle })] }), _jsxs(Grid, { container: true, rowSpacing: { xs: 4, md: 6.75 }, columnSpacing: { xs: 3, md: 10 }, sx: { mt: 0, px: { xs: 3, md: 11 } }, children: [_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(Paper, { square: true, sx: {
                                        borderRadius: 1,
                                        height: "100%",
                                        pt: 2.5,
                                        pb: 3.5,
                                        px: 3.5,
                                    }, children: firstBlockFeatures }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(Paper, { square: true, sx: {
                                        borderRadius: 1,
                                        height: "100%",
                                        pt: 2.5,
                                        pb: 3.5,
                                        px: 3.5,
                                    }, children: secondBlockFeatures }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(Paper, { square: true, sx: {
                                        borderRadius: 1,
                                        height: "100%",
                                        pt: 2.5,
                                        pb: 3.5,
                                        px: 3.5,
                                    }, children: thirdBlockFeatures }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(Paper, { square: true, sx: {
                                        borderRadius: 1,
                                        height: "100%",
                                        pt: 2.5,
                                        pb: 3.5,
                                        px: 3.5,
                                    }, children: forthBlockFeatures }) })] }), _jsx(Box, { sx: { mt: 7.125 }, children: customTabs }), _jsx(Box, { sx: {
                            mt: { xs: 11, md: 13, lg: 15.5 },
                            mb: { xs: 11, md: 13, lg: 15.5 },
                        }, children: groupPresentation }), _jsxs(Box, { sx: {
                            display: { xs: "none", md: "block" },
                            textAlign: "center",
                            px: 4,
                        }, children: [_jsx(Typography, { variant: "h2", children: establishmentsTitle }), _jsx(Typography, { variant: "h3", sx: { color: "colors.gris_2" }, children: establishmentsSubtitle }), _jsx(Button, { sx: {
                                    m: { xs: 2, sm: 4, md: 8 },
                                    mb: { xs: 4, md: 4 },
                                }, variant: "cta", component: HashLink, to: "/recherche-detaillee", endIcon: _jsx(SearchIcon, { sx: {
                                        color: "colors.noir",
                                        bgcolor: "colors.blanc",
                                        border: "1px solid",
                                        borderColor: "colors.blanc",
                                        borderRadius: 20,
                                    } }), children: "Trouver ma r\u00E9sidence en quelques clics" })] }), establishmentsForHighlightedListing.length > 0 && (_jsx(LazyLoad, { children: _jsx(Grid, { container: true, spacing: 4, p: 4, pt: 0, my: 0, sx: { display: { xs: "none", md: "flex" } }, children: establishmentsForHighlightedListing.map(function (establishment) { return (_jsx(Grid, { item: true, md: 4, lg: 3, children: _jsx(EstablishmentCard, { establishment: establishment }) }, establishment.id)); }) }) })), _jsx(Box, { sx: {
                            display: { xs: "none", md: "block" },
                            textAlign: "center",
                            px: 4,
                            mt: { xs: 9, md: 9, lg: 11.5 },
                        }, children: _jsx(Typography, { variant: "h2", children: googleMapEstablishmentsTitle }) }), _jsx(Box, { sx: { display: { xs: "none", md: "block" } }, p: 4, children: _jsx(LazyLoad, { children: _jsx(GoogleMapEstablishments, { center: { lat: 46.52863469527167, lng: 2.43896484375 }, zoom: 6, establishments: establishments }) }) }), _jsx(Box, { sx: {
                            mt: { xs: 11, md: 9, lg: 11.5 },
                            mb: { xs: 4.75, md: 6.75, lg: 9.25 },
                        }, children: financialHelp })] })] }));
};
export default HomeTemplate;
