import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const Container = (props) => {
  const { children, justifyContent, width, mobileWidth } = props;

  return (
    <Box display="flex" flexDirection="row" justifyContent={justifyContent}>
      <Box sx={{ width: { xs: `${mobileWidth}%`, lg: `${width}%` } }}>{children}</Box>
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  justifyContent: PropTypes.string.isRequired,
};

Container.defaultProps = {
  children: null,
  width: null,
  mobileWidth: null,
};

export default Container;
