import React, { useContext, useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import { PageVersionContextProvider } from "cms/components/PageVersionContext";
import PageSwitcher from "cms/front-office/components/PageSwitcher";
import MessageContext from "cms/components/MessageContext";
import Snackbar from "cms/components/Snackbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SiteContext from "cms/components/SiteContext";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import contentsTypes from "cms/enums/contentsTypes";
import { theme as daDesignSystemTheme } from "@docaposte-agility/da-design-system";

const messageTypes = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

const snackbarReducer = (snackbarState, action) => {
  const { type, message } = action || {};
  switch (type) {
    case messageTypes.SUCCESS:
      return {
        open: true,
        severity: "success",
        message,
      };
    case messageTypes.WARNING:
      return {
        open: true,
        severity: "warning",
        message,
      };
    case messageTypes.ERROR:
      return {
        open: true,
        duration: null,
        severity: "error",
        message,
      };
    default:
      return {
        ...snackbarState,
        open: false,
      };
  }
};

const App = () => {
  const Layout = CmsHooks.getHook(HOOKS.App_Layout) || (({ children }) => children);
  const routes = CmsHooks.getHook(HOOKS.App_routes);
  const projectTheme = CmsHooks.getHook(HOOKS.THEME);
  const getTheme = CmsHooks.getHook(HOOKS.GET_THEME);

  const site = useContext(SiteContext);

  const [snackbar, dispatch] = useReducer(snackbarReducer, { open: false });

  const displaySuccess = (message) => {
    dispatch({ type: messageTypes.SUCCESS, message });
  };

  const displayWarning = (message) => {
    dispatch({ type: messageTypes.WARNING, message });
  };

  const displayError = (message) => {
    dispatch({ type: messageTypes.ERROR, message });
  };

  const handleCloseSnackbar = () => {
    dispatch();
  };

  const { contents = [] } = site;

  const templatesSettings = contents && contents.find((c) => c.type === contentsTypes.TEMPLATES_SETTINGS);

  const theme = (typeof getTheme === "function" && getTheme({ site })) || projectTheme || createTheme();

  return (
    <ThemeProvider theme={theme}>
      <PageVersionContextProvider>
        <MessageContext.Provider
          value={{
            displaySuccess,
            displayWarning,
            displayError,
          }}
        >
          <ThemeProvider
            theme={createTheme(
              {
                components: {
                  MuiAlert: daDesignSystemTheme?.components?.MuiAlert,
                  MuiAlertTitle: daDesignSystemTheme?.components?.MuiAlertTitle,
                  MuiSnackbarContent: daDesignSystemTheme?.components?.MuiSnackbarContent,
                  MuiSnackbar: daDesignSystemTheme?.components?.MuiSnackbar,
                },
              },
              theme,
            )}
          >
            <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
          </ThemeProvider>
          <Layout>
            <Switch>
              {routes && routes.map((route) => <Route key={route.path} {...route} />)}
              <Route path="/">
                <PageSwitcher templatesSettings={templatesSettings} />
              </Route>
            </Switch>
          </Layout>
        </MessageContext.Provider>
      </PageVersionContextProvider>
    </ThemeProvider>
  );
};

export default App;
