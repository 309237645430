var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from "axios";
import { useQuery } from "react-query";
export var entityName = "page-versions";
export var getPageVersions = function (params) {
    if (params === void 0) { params = {}; }
    return axios.get("/page-versions", params).then(function (r) { var _a; return (_a = r.data) === null || _a === void 0 ? void 0 : _a.data; });
};
export var usePageVersionList = function (params, options) {
    if (params === void 0) { params = {}; }
    if (options === void 0) { options = {}; }
    return useQuery([entityName, params], function () { return getPageVersions(params); }, options);
};
export var enhanceListWithPageVersions = function (list, versionIds) {
    var ids = __spreadArray([], versionIds, true);
    var promises = [];
    var versionsLimitByRequest = 30;
    for (var i = 0; i < versionIds.length; i += versionsLimitByRequest) {
        var partialIds = ids.slice(i, i + versionsLimitByRequest);
        if (partialIds.length > 0) {
            promises.push(getPageVersions({
                params: {
                    filter: ["pageId||in||".concat(partialIds.join(","))],
                    offset: 0,
                    per_page: partialIds.length,
                },
            }));
        }
    }
    return Promise.all(promises)
        .then(function (groupedVersions) {
        var _a;
        return (_a = []).concat.apply(_a, groupedVersions);
    })
        .then(function (versions) {
        return list
            .map(function (item) {
            var cmsPageId = item.cmsPageId;
            var pageVersion = versions === null || versions === void 0 ? void 0 : versions.find(function (version) { return version.pageId === cmsPageId; });
            return __assign(__assign({}, item), { pageVersion: pageVersion });
        })
            .filter(function (item) { return Boolean(item.pageVersion); });
    });
};
export var useEnhanceListWithPageVersions = function (list, options) {
    if (options === void 0) { options = {}; }
    var versionIds = list.map(function (item) { return item.cmsPageId; }).filter(function (id) { return Boolean(id); });
    return useQuery([entityName, versionIds], function () { return enhanceListWithPageVersions(list, versionIds); }, __assign({ enabled: versionIds.length > 0 }, options));
};
