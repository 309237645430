import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { withRouter } from "react-router-dom";
import PageService from "cms/services/PageService";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import axios from "axios";

const PageVersionContext = React.createContext({
  currentPageVersion: {},
  setCurrentPageVersion: () => {},
  loading: false,
});
export default PageVersionContext;

axios.interceptors.request.use(
  (config) => {
    const appRoutes = CmsHooks.getHook(HOOKS.App_routes) || [];
    // Prevent call to /getPageInfos if requested pathname param is not a 'cms page'
    if (
      config.url === "/getPageInfos" &&
      config.params?.pathname &&
      appRoutes.find((route) => {
        const { path, exact } = route;
        if (exact) {
          return config.params.pathname === path;
        }
        return config.params.pathname.startsWith(path);
      })
    ) {
      return {
        adapter: () => new Promise((resolve) => resolve({ data: null })),
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export const PageVersionContextProvider = withRouter((props) => {
  const { location, children } = props;

  const { data: page, isFetching } = useQuery(["pageversion", location.pathname], () =>
    PageService.getPageInfos(location.pathname),
  );
  const [currentPageVersion, setCurrentPageVersion] = useState(page || null);
  const [loading, setIsLoading] = useState(isFetching);

  useEffect(() => {
    if (!isFetching && page) {
      setCurrentPageVersion(page);
    }
    setIsLoading(isFetching);
  }, [page, isFetching]);

  const handleChangeCurrentVersion = (pageVersion) => {
    if (pageVersion && pageVersion.id !== page?.id) {
      setCurrentPageVersion(pageVersion);
      document.documentElement.scrollTop = 0;
    }
  };

  return (
    <PageVersionContext.Provider
      value={{
        currentPageVersion,
        setCurrentPageVersion: handleChangeCurrentVersion,
        loading,
      }}
    >
      {children}
    </PageVersionContext.Provider>
  );
});
