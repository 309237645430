var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { usePageVersionList } from "services/pageversion.service";
import dayjs from "dayjs";
import { Typography, Grid, Box } from "@mui/material";
import { Templates } from "types/templates.enum";
import Image, { ImageContainer } from "cms/editableComponents/Image";
import SiteContext from "cms/components/SiteContext";
import ItemsCard from "components/ItemsCard";
import ArticlesCarousel from "components/ArticlesCarousel";
import { getDateFromTimestamp } from "cms/utils/commonUtils";
import ItemTags from "components/ItemTags";
import { createQueryParams, getQueryParams } from "cms/utils/urlUtils";
import versionStatuses from "cms/enums/versionStatuses";
import { useArticlesPageVersion, useArticlesTagsByParent, useLastArticlesByParent } from "services/article.service";
var ArticleTemplate = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var page = props.page;
    var id = page.id, contents = page.contents, title = page.title, shortDescription = page.shortDescription, publishedDate = page.publishedDate, updated = page.updated, _h = page.page, pageInfo = _h === void 0 ? {} : _h, _j = page.tags, tags = _j === void 0 ? [] : _j;
    var parentId = pageInfo.parentId;
    var imagesGallery = contents.imagesGallery, dynamicPart = contents.dynamicPart;
    var images = (_a = imagesGallery === null || imagesGallery === void 0 ? void 0 : imagesGallery.props) === null || _a === void 0 ? void 0 : _a.images;
    var site = useContext(SiteContext);
    var history = useHistory();
    var search = useLocation().search;
    var queryParams = getQueryParams(search) || {};
    var parentTitle = queryParams.title;
    var date = publishedDate || updated;
    var articlesPageVersion = useArticlesPageVersion(site).data;
    var establishmentParentPageVersions = usePageVersionList({
        params: {
            filter: [
                "pageId||in||".concat(parentId),
                "status||eq||".concat(versionStatuses.PUBLISHED),
                "template||eq||".concat(Templates.ESTABLISHMENT),
            ],
            offset: 0,
        },
    }).data;
    var allTags = useArticlesTagsByParent(parentId, site).data;
    var lastArticles = useLastArticlesByParent(parentId, site, ["id||ne||".concat(id)]).data;
    var _k = lastArticles || {}, _l = _k.data, articles = _l === void 0 ? [] : _l, _m = _k.total, articlesNumber = _m === void 0 ? 0 : _m;
    var articlesForListing = articles.slice(0, 10);
    if (parentTitle) {
        articles = articles.map(function (article) {
            var fullPath = article.fullPath;
            return __assign(__assign({}, article), { fullPath: "".concat(fullPath).concat(fullPath.includes("?") ? "&" : "?").concat(createQueryParams({
                    title: parentTitle,
                })) });
        });
    }
    articlesNumber = articlesNumber + 1;
    var pathname = React.useMemo(function () {
        var _a = (articlesPageVersion || {}).fullPath, path = _a === void 0 ? "/" : _a;
        if (establishmentParentPageVersions && establishmentParentPageVersions.length > 0) {
            var title_1 = establishmentParentPageVersions[0].title;
            return "".concat(path, "?").concat(createQueryParams({
                parentId: parentId,
                title: title_1,
            }));
        }
        return path;
    }, [articlesPageVersion, establishmentParentPageVersions, parentId]);
    var itemsText = React.useMemo(function () {
        if (!articlesNumber || articlesNumber === 0) {
            return "Aucun article";
        }
        if (articlesNumber === 1) {
            return "1 article";
        }
        return "".concat(articlesNumber, " articles");
    }, [articlesNumber]);
    var filterTags = function (tag) {
        history.push("".concat(pathname).concat(pathname.includes("?") ? "&" : "?").concat(createQueryParams({
            tag: tag,
        })));
    };
    return (_jsxs("article", { children: [_jsxs(Grid, { container: true, columnSpacing: 4, sx: { pt: 5, px: 4 }, children: [_jsxs(Grid, { item: true, xs: 12, md: 8, children: [images !== "" &&
                                (images && !Array.isArray(images.image) ? (_jsxs(_Fragment, { children: [_jsx(Box, { sx: {
                                                position: "relative",
                                            }, children: _jsx(Box, { sx: {
                                                    position: "absolute",
                                                    top: 16,
                                                    right: 16,
                                                    zIndex: 10,
                                                }, children: _jsx(ItemTags, { tags: tags || [] }) }) }), _jsx(ImageContainer, { ratio: 45, children: _jsx(Image, { file: {
                                                    url: ((_c = (_b = images === null || images === void 0 ? void 0 : images.image) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.url) || "/default-image-item.jpg",
                                                }, alt: ((_e = (_d = images === null || images === void 0 ? void 0 : images.image) === null || _d === void 0 ? void 0 : _d.file) === null || _e === void 0 ? void 0 : _e.alt) || "", title: ((_g = (_f = images === null || images === void 0 ? void 0 : images.image) === null || _f === void 0 ? void 0 : _f.file) === null || _g === void 0 ? void 0 : _g.title) || "", disableLazy: true }) })] })) : (_jsxs(Box, { sx: {
                                        position: "relative",
                                    }, children: [_jsx(Box, { sx: {
                                                position: "absolute",
                                                top: 16,
                                                right: 16,
                                                zIndex: 10,
                                            }, children: _jsx(ItemTags, { tags: tags || [] }) }), imagesGallery] }))), _jsxs(Box, { sx: { textAlign: "center" }, children: [_jsx(Typography, { variant: "h1", sx: { mt: 4 }, children: title }), dayjs(date).isValid() && (_jsx(Typography, { sx: {
                                            fontStyle: "italic",
                                            fontWeight: 400,
                                            fontSize: "1.143rem",
                                            lineHeight: "1.429rem",
                                            color: "colors.bleu_clair",
                                        }, children: getDateFromTimestamp(date, "DD/MM/YYYY") })), _jsx(Typography, { variant: "slogan", children: shortDescription })] }), _jsx(Box, { sx: { display: "flex", justifyContent: "center" }, children: _jsx(Box, { sx: { width: { xs: "100%", md: "75%", lg: "60%" } }, children: dynamicPart }) })] }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(ItemsCard, { itemsTitle: parentTitle || "Actualités ARPAVIE", recentTitle: "Articles r\u00E9cents", itemsText: itemsText, recentItems: articles.slice(0, 4), tags: allTags, onClickTag: filterTags }) })] }), articlesForListing.length > 0 && (_jsx(Box, { sx: { px: 7 }, children: _jsx(ArticlesCarousel, { articles: articlesForListing }) }))] }));
};
export default ArticleTemplate;
