import React from "react";
import PropTypes from "prop-types";
import MuiButton from "@mui/material/Button";
import Icon from "cms/editableComponents/Icon";
import Box from "@mui/material/Box";

const Button = (props) => {
  const { icon: constIcon, justifyContent, ...others } = props;
  let { icon } = props;
  if (typeof icon === "string") {
    icon = { icon };
  }

  const btn = <MuiButton endIcon={icon && icon.icon && <Icon {...icon} title="Voir" />} {...others} />;

  if (justifyContent && justifyContent !== "flex-start") {
    return (
      <Box display="flex" justifyContent={justifyContent}>
        {btn}
      </Box>
    );
  }

  return btn;
};

Button.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  justifyContent: PropTypes.oneOf(["flex-start", "center", "flex-end"]),
};

Button.defaultProps = {
  icon: null,
  justifyContent: "flex-start",
};

export default Button;
