import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Container from "cms/editableComponents/Container";

const commonStyled = { border: "1px solid #999", padding: "16px", textAlign: "center" };

const StyledContainer = styled(Container)(commonStyled);

const StyledDiv = styled("div")(commonStyled);

const ContainerPreview = () => {
  return (
    <div>
      <Box mb={3}>
        <Container justifyContent="flex-start" width="50">
          <StyledDiv>Conteneur 50% aligné à gauche</StyledDiv>
        </Container>
      </Box>
      <Box mb={3}>
        <StyledContainer justifyContent="center" width="30">
          <StyledDiv>Conteneur 30% aligné au centre</StyledDiv>
        </StyledContainer>
      </Box>
      <Box mb={3}>
        <StyledContainer justifyContent="flex-end" width="60">
          <StyledDiv>Conteneur 60% aligné à droite</StyledDiv>
        </StyledContainer>
      </Box>
    </div>
  );
};

export default ContainerPreview;
