var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Checkbox, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import Label from "components/Label";
import SearchProximityElements from "components/SearchProximityElements";
import SearchEstablishmentPriceType from "components/SearchEstablishmentPriceType";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
import SearchStep from "routes/Search/SearchStep";
import { useFeatureList } from "services/feature.service";
import { FeatureCategory } from "types/feature/feature-category.enum";
import { EstablishmentType } from "types/establishment/establishment-type.enum";
import { getEstablishmentTypeFromSearchParams } from "utils/establishmentSearchUtils";
export var summaryTitle = "Critères supplémentaires";
var AdditionalCriteriaStep = function (props) {
    var form = props.form, setForm = props.setForm, handleValidateStep = props.handleValidateStep, nextStepButton = props.nextStepButton;
    var specificTypes = form.specificTypes, PMR = form.PMR;
    var allFeatures = useFeatureList({
        params: {
            per_page: 200,
        },
    }).data;
    var _a = React.useState([]), allSearchFeatures = _a[0], setAllSearchFeatures = _a[1];
    React.useEffect(function () {
        getEnvironmentPromise().then(function (_a) {
            var search_features = _a.search_features;
            if (search_features) {
                setAllSearchFeatures(search_features.split(","));
            }
        });
    }, []);
    var searchFeatures = React.useMemo(function () {
        var _a;
        return ((_a = allFeatures === null || allFeatures === void 0 ? void 0 : allFeatures.data) === null || _a === void 0 ? void 0 : _a.filter(function (f) { return allSearchFeatures.includes(f.name); })) || [];
    }, [allFeatures, allSearchFeatures]);
    var searchTherapeuticFeatures = React.useMemo(function () {
        return searchFeatures.filter(function (f) { return f.category === FeatureCategory.THERAPEUTIC_ACTIVITIES; });
    }, [searchFeatures]);
    var searchEnvironmentFeatures = React.useMemo(function () {
        return searchFeatures.filter(function (f) { return f.category === FeatureCategory.ENVIRONMENT; });
    }, [searchFeatures]);
    var searchEveryDayFeatures = React.useMemo(function () {
        return searchFeatures.filter(function (f) { return f.category === FeatureCategory.EVERYDAY_LIFE; });
    }, [searchFeatures]);
    var _b = searchEveryDayFeatures[0] || {}, id = _b.id, name = _b.name;
    var handleSelectSpecificTypes = function (e) {
        var value = e.target.value;
        var newValue = specificTypes.includes(value)
            ? specificTypes.filter(function (type) { return type !== value; })
            : __spreadArray(__spreadArray([], specificTypes, true), [value], false);
        setForm(__assign(__assign({}, form), { specificTypes: newValue }));
    };
    var handleChangeSwitch = function (e) {
        var _a = e.target, checked = _a.checked, value = _a.value;
        if (checked) {
            setForm(__assign(__assign({}, form), { PMR: value }));
        }
        else {
            setForm(__assign(__assign({}, form), { PMR: "" }));
        }
    };
    var establishmentType = React.useMemo(function () {
        return getEstablishmentTypeFromSearchParams(form);
    }, [form]);
    return (_jsx(SearchStep, { title: summaryTitle, subtitle: "Quels sont vos besoins", button: nextStepButton, accordions: [
            {
                title: "Dans quel cas ai-je besoin d’une prise en charge spécifique ?",
                text: "Vous êtes en perte d’autonomie en stade avancé ou atteint d’une maladie évolutive handicapante comme par exemple la maladie d’Alzheimer ou maladie apparentée ? Vous avez besoin de vivre dans un milieu sécurisé et de bénéficier d’une prise en charge dans une unité spécifique. Cette prise en charge spécifique permet un accompagnement médical dans un cadre sécurisé et une architecture adaptée.",
            },
            {
                title: "Qu’est-ce qu’une résidence adaptée PMR ?",
                text: "C’est une résidence adaptée aux personnes à mobilité réduite.  Ainsi, la résidence répond à des critères normés, notamment en terme d’accessibilité (douche à l’italienne, rails pour transfert…).",
            },
        ], handleValidateStep: handleValidateStep, children: _jsxs(Grid, { item: true, xs: 12, md: 6, container: true, direction: "column", children: [establishmentType === EstablishmentType.EHPAD && searchTherapeuticFeatures.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Label, { children: "S\u00E9lectionnez le(s) type(s) de prise en charge sp\u00E9cifique(s)" }), searchTherapeuticFeatures.map(function (therapeutic) { return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { value: therapeutic.id, 
                                // @ts-ignore
                                checked: specificTypes.includes(therapeutic.id), onChange: handleSelectSpecificTypes }), label: _jsx(Typography, { variant: "donnee", children: therapeutic.name }) }, therapeutic.id)); })] })), establishmentType === EstablishmentType.RA && (_jsxs(_Fragment, { children: [_jsx(Label, { children: "S\u00E9lectionnez la/les taille(s) recherch\u00E9e(s)" }), _jsx(SearchEstablishmentPriceType, { form: form, setForm: setForm })] })), searchEnvironmentFeatures.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { mt: "41px" }, children: _jsx(Label, { children: "S\u00E9lectionnez les \u00E9l\u00E9ments souhait\u00E9s \u00E0 proximit\u00E9 " }) }), _jsx(SearchProximityElements, { form: form, setForm: setForm, searchEnvironmentFeatures: searchEnvironmentFeatures })] })), searchEveryDayFeatures[0] && (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { mt: "49px" }, children: _jsx(Label, { children: "La r\u00E9sidence doit \u00EAtre adapt\u00E9e aux personnes \u00E0 mobilit\u00E9 r\u00E9duite" }) }), _jsxs(Grid, { item: true, container: true, alignItems: "center", children: [_jsx(Switch, { id: "search-additional-criteria-step-pmr", name: "PMR", checked: !!PMR, value: id, onChange: handleChangeSwitch }), _jsx(Typography, { variant: "donnee", children: _jsx(Box, { component: "label", htmlFor: "search-additional-criteria-step-pmr", children: name }) })] })] }))] }) }));
};
export default AdditionalCriteriaStep;
