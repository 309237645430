import React from "react";
import PropTypes from "prop-types";
import SVGIcon from "cms/back-office/icons/SVGIcon";

const InfoBlockIcon = (props) => {
  const { backgroundColor } = props;

  return (
    <svg viewBox="0 0 62 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79688 0C1.69231 0 0.796875 0.89543 0.796875 2V22C0.796875 23.1046 1.6923 24 2.79687 24H22.7969V0H2.79688ZM23.7969 0V24H59.2031C60.3077 24 61.2031 23.1046 61.2031 22V2C61.2031 0.895431 60.3077 0 59.2031 0H23.7969ZM12.4969 9.9V8.5H11.0969V9.9H12.4969ZM12.4969 15.5V11.3H11.0969V15.5H12.4969ZM4.79688 12C4.79688 8.136 7.93288 5 11.7969 5C15.6609 5 18.7969 8.136 18.7969 12C18.7969 15.864 15.6609 19 11.7969 19C7.93288 19 4.79688 15.864 4.79688 12ZM6.19688 12C6.19688 15.087 8.70988 17.6 11.7969 17.6C14.8839 17.6 17.3969 15.087 17.3969 12C17.3969 8.913 14.8839 6.4 11.7969 6.4C8.70988 6.4 6.19688 8.913 6.19688 12ZM28.2031 8H57.2031V10H28.2031V8ZM57.2031 14H28.2031V16H57.2031V14Z"
        fill={backgroundColor}
      />
    </svg>
  );
};

InfoBlockIcon.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
};

export default SVGIcon(InfoBlockIcon);
