var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Link, Radio, RadioGroup, Tooltip, Typography, } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormInput from "components/FormInput";
import { checkFrenchNameRules, checkEmailRules, checkMessageLength } from "cms/utils/commonUtils";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
import ContactService from "services/ContactService";
import CaptchaService from "services/CaptchaService";
import { SubjectForm } from "types/subject-fom.enum";
import { CheckCaptchaStatus } from "types/captcha/check-captcha-status.enum";
import { AntibotResult } from "types/captcha/antibot-result.enum";
var ContactForm = function (props) {
    var _a;
    var overrideDefaultForm = props.overrideDefaultForm, buttonLabel = props.buttonLabel, handleButtonAction = props.handleButtonAction, formSubmitted = props.formSubmitted, handleFormSubmitted = props.handleFormSubmitted, handleReturn = props.handleReturn, additionalFields = props.additionalFields, additionalFieldsValues = props.additionalFieldsValues, _b = props.additionalFieldsError, additionalFieldsError = _b === void 0 ? false : _b, _c = props.subjectForm, subjectForm = _c === void 0 ? null : _c;
    var _d = useState(__assign({ civility: "", name: "", firstName: "", email: "", phoneNumber: "", subject: "", message: "" }, overrideDefaultForm)), form = _d[0], setForm = _d[1];
    var _e = useState(false), loader = _e[0], setLoader = _e[1];
    var _f = useState(false), loaderCaptcha = _f[0], setLoaderCaptcha = _f[1];
    var _g = useState(CheckCaptchaStatus.init), checkCaptchaStatus = _g[0], setCheckCaptchaStatus = _g[1];
    var _h = useState(false), formSended = _h[0], setFormSended = _h[1];
    var _j = useState(""), sendError = _j[0], setSendError = _j[1];
    var _k = useState(false), captchaAntibotInfo = _k[0], setCaptchaAntibotInfo = _k[1];
    var _l = useState(AntibotResult.unknown), antibotResult = _l[0], setAntibotResult = _l[1];
    var civility = form.civility, name = form.name, firstName = form.firstName, email = form.email, phoneNumber = form.phoneNumber, subject = form.subject, recipient = form.recipient, message = form.message;
    var consentText = (_a = {},
        _a[SubjectForm.ESTABLISHMENT] = (_jsxs(_Fragment, { children: ["Nous vous remercions de l\u2019int\u00E9r\u00EAt que vous portez \u00E0 l\u2019association ARPAVIE et de la confiance que vous nous accordez en nous transmettant toutes informations que vous consid\u00E9rez pertinentes au traitement de votre demande. Votre message parviendra aux collaborateurs en charge de la communication et du marketing. En fonction de l\u2019objet de votre demande, elle pourra \u00EAtre transmise \u00E0 un autre service interne ou \u00E0 la direction d\u2019une r\u00E9sidence d\u2019ARPAVIE. Sauf avis contraire de votre part, pour les besoins de suivi interne, nous conservons vos donn\u00E9es pour une dur\u00E9e maximale de 1 an \u00E0 compter du dernier contact \u00E9manant de votre part. Les demandes d\u2019h\u00E9bergement sont conserv\u00E9es pendant un an. Pour plus d\u2019informations concernant le traitement de vos donn\u00E9es et vos droits, consulter notre", " ", _jsx(Link, { href: "/donnees-personnelles-et-cookies", target: "_blank", children: "Politique Donn\u00E9es Personnelles" }), "."] })),
        _a[SubjectForm.ESTABLISHMENT_DETAIL] = (_jsxs(_Fragment, { children: ["Nous vous remercions de l\u2019int\u00E9r\u00EAt que vous portez \u00E0 l\u2019association ARPAVIE et de la confiance que vous nous accordez en nous transmettant toutes informations que vous consid\u00E9rez pertinentes au traitement de votre demande. ", _jsx("b", { children: "Votre message parviendra \u00E0 la direction d\u2019une r\u00E9sidence." }), " Sauf avis contraire de votre part, pour les besoins de suivi interne, nous conservons vos donn\u00E9es pour une dur\u00E9e maximale d\u2019un an \u00E0 compte du dernier contact \u00E9manant de votre part. Les demandes d\u2019h\u00E9bergement sont conserv\u00E9es pendant un an. Pour plus d\u2019informations concernant le traitement de vos donn\u00E9es et vos droits, consultez notre", " ", _jsx(Link, { href: "/donnees-personnelles-et-cookies", target: "_blank", children: "Politique Donn\u00E9es Personnelles" }), "."] })),
        _a[SubjectForm.PARTNERSHIP] = (_jsxs(_Fragment, { children: ["Nous vous remercions de l\u2019int\u00E9r\u00EAt que vous portez \u00E0 l\u2019association ARPAVIE et de la confiance que vous nous accordez en nous transmettant toutes informations que vous consid\u00E9rez pertinentes au traitement de votre demande. Votre message parviendra aux collaborateurs en charge de la communication et du marketing. En fonction de l\u2019objet de votre demande, elle pourra \u00EAtre transmise \u00E0 un autre service interne ou \u00E0 la direction d\u2019une r\u00E9sidence d\u2019ARPAVIE. Sauf avis contraire de votre part, pour les besoins de suivi interne, nous conservons vos donn\u00E9es pour une dur\u00E9e maximale de 1 an \u00E0 compter du dernier contact \u00E9manant de votre part. Pour plus d\u2019informations concernant le traitement de vos donn\u00E9es et vos droits, consulter notre", " ", _jsx(Link, { href: "/donnees-personnelles-et-cookies", target: "_blank", children: "Politique Donn\u00E9es Personnelles" }), "."] })),
        _a[SubjectForm.ARPAVIE_DOM_SERVICES] = (_jsxs(_Fragment, { children: ["Nous vous remercions de l\u2019int\u00E9r\u00EAt que vous portez \u00E0 l\u2019association ARPAVIE et de la confiance que vous nous accordez en nous transmettant toutes informations que vous consid\u00E9rez pertinentes au traitement de votre demande. Votre message parviendra aux collaborateurs en charge de la communication et du marketing. Sauf avis contraire de votre part, pour les besoins de suivi interne, nous conservons vos donn\u00E9es pour une dur\u00E9e maximale d\u2019un an.", " ", _jsx("b", { children: "Par ailleurs, en remplissant ce formulaire, vous autorisez ARPAVIE \u00E0 transmettre vos donn\u00E9es \u00E0 ARPAVIE @dom, afin d\u2019obtenir une r\u00E9ponse \u00E0 votre demande." }), " ", "Pour plus d\u2019informations concernant le traitement de vos donn\u00E9es et vos droits, consulter notre", " ", _jsx(Link, { href: "/donnees-personnelles-et-cookies", target: "_blank", children: "Politique Donn\u00E9es Personnelles" }), "."] })),
        _a);
    var handleSubmit = function (event) {
        event.preventDefault();
        handleValidate();
    };
    var handleFormChange = function (e) {
        var _a;
        var _b = e.target, type = _b.type, value = _b.value, checked = _b.checked, fieldName = _b.name;
        setForm(__assign(__assign({}, form), (_a = {}, _a[fieldName] = type === "checkbox" ? Boolean(checked) : value, _a)));
    };
    var errors = useMemo(function () {
        var newErrors = {
            civility: !civility,
            name: !checkFrenchNameRules({ key: name, length: 100 }),
            firstName: !checkFrenchNameRules({ key: firstName, length: 40, optional: true }),
            email: !checkEmailRules({ key: email }),
            message: !checkMessageLength({ key: message }),
            phoneNumber: !isValidPhoneNumber(phoneNumber, "FR"),
            additionalFields: additionalFieldsError,
        };
        return newErrors;
    }, [civility, name, firstName, email, phoneNumber, additionalFieldsError, message]);
    var showError = function (key) { return formSubmitted && errors[key]; };
    var isFormValid = Object.values(errors).reduce(function (a, b) { return a && !b; }, true);
    var sendMail = function () {
        var formData = new FormData();
        formData.append("civility", civility);
        formData.append("name", name);
        formData.append("firstName", firstName);
        formData.append("email", email);
        formData.append("phoneNumber", phoneNumber);
        formData.append("subject", subject);
        if (recipient) {
            formData.append("recipient", recipient);
        }
        if (additionalFieldsValues) {
            additionalFieldsValues.forEach(function (field) {
                formData.append(field.name, field.value);
            });
        }
        formData.append("message", decodeURIComponent(encodeURIComponent(message).replace(/%0A/g, "<br/>")));
        ContactService.postMail(formData).then(function (response) {
            if (response && response.status && response.status === 400) {
                setSendError(response.message);
            }
            else {
                setFormSended(true);
            }
            setLoader(false);
        });
    };
    var getCaptchaAntibotInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var captchaKey, captchaURL, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoaderCaptcha(true);
                    captchaKey = "";
                    captchaURL = "";
                    getEnvironmentPromise().then(function (_a) {
                        var liveidentity_key = _a.liveidentity_key, liveidentity_URL = _a.liveidentity_URL;
                        if (liveidentity_key && liveidentity_URL) {
                            captchaKey = liveidentity_key;
                            captchaURL = liveidentity_URL;
                        }
                    });
                    return [4 /*yield*/, CaptchaService.getAntibotInfo()];
                case 1:
                    response = _a.sent();
                    // @ts-ignore
                    LI_ANTIBOT.loadAntibot([
                        "PICTO",
                        "AUDIO",
                        "FR",
                        captchaKey,
                        captchaURL,
                        "",
                        "",
                        response.antibotId,
                        response.requestId,
                        true,
                    ]);
                    setCaptchaAntibotInfo(true);
                    setLoaderCaptcha(false);
                    setAntibotResult(response.antibotResult);
                    return [2 /*return*/];
            }
        });
    }); };
    var checkToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var antibotToken, resultToken, responseAntibotToken;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!captchaAntibotInfo) return [3 /*break*/, 4];
                    antibotToken = ((_a = document.getElementById("li-antibot-token")) === null || _a === void 0 ? void 0 : _a.value) || null;
                    resultToken = "";
                    if (!antibotToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, CaptchaService.verifyAntibotToken(antibotToken)];
                case 1:
                    responseAntibotToken = _b.sent();
                    resultToken = responseAntibotToken === null || responseAntibotToken === void 0 ? void 0 : responseAntibotToken.result;
                    return [3 /*break*/, 3];
                case 2:
                    setCheckCaptchaStatus(CheckCaptchaStatus.to_validate);
                    _b.label = 3;
                case 3: return [2 /*return*/, resultToken];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        getCaptchaAntibotInfo();
    }, []);
    var handleValidate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isCaptchaValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    handleFormSubmitted(true);
                    return [4 /*yield*/, checkToken()];
                case 1:
                    isCaptchaValid = _a.sent();
                    if (isFormValid) {
                        if (isCaptchaValid === "SUCCESS") {
                            setLoader(true);
                            setCheckCaptchaStatus(CheckCaptchaStatus.validated);
                            sendMail();
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: !formSended ? (_jsx(_Fragment, { children: _jsx("form", { action: "/", method: "POST", onSubmit: handleSubmit, children: loader ? (_jsx(Grid, { container: true, justifyContent: "center", alignItems: "center", sx: {
                        minHeight: "500px",
                        minWidth: { xs: " 350px", sm: "400px" },
                    }, children: _jsx(CircularProgress, {}) })) : (_jsx(_Fragment, { children: _jsxs(Grid, { container: true, justifyContent: "center", spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, container: true, direction: "column", alignItems: "flex-start", children: _jsxs(FormControl, { error: showError("civility"), sx: { width: "100%" }, children: [_jsx(Typography, { variant: "label", component: FormLabel, sx: { mb: "6px" }, children: "Civilit\u00E9 *" }), _jsxs(RadioGroup, { row: true, id: "civility", name: "civility", onChange: handleFormChange, children: [_jsx(FormControlLabel, { value: "Madame", control: _jsx(Radio, { sx: {
                                                            color: showError("civility") ? "colors.rouge_clair" : "inherit",
                                                        } }), label: "Madame" }), _jsx(FormControlLabel, { value: "Monsieur", control: _jsx(Radio, { sx: {
                                                            color: showError("civility") ? "colors.rouge_clair" : "inherit",
                                                        } }), label: "Monsieur" })] }), showError("civility") && _jsx(FormHelperText, { children: "Veuillez renseigner votre civilit\u00E9" })] }) }), _jsx(FormInput, { name: "name", label: "Nom", value: name, error: showError("name"), formHelperText: "Votre nom n'est pas valide", onChange: handleFormChange, required: true }), _jsx(FormInput, { name: "firstName", label: "Pr\u00E9nom", value: firstName, error: showError("firstName"), formHelperText: "Votre pr\u00E9nom n'est pas valide", onChange: handleFormChange, required: false }), _jsx(Typography, { variant: "body1", sx: { px: 2, mt: 3, mb: 0.5 }, children: "Merci de renseigner au moins un moyen de contact et de nous pr\u00E9ciser dans votre message, si vous ne souhaitez pas \u00EAtre contact\u00E9(e) par t\u00E9l\u00E9phone." }), _jsx(FormInput, { name: "email", label: "E-mail", value: email, error: showError("email"), formHelperText: "Le format de l'adresse mail n'est pas correct", onChange: handleFormChange, required: false }), _jsx(FormInput, { name: "phoneNumber", label: "T\u00E9l\u00E9phone", value: phoneNumber, error: showError("phoneNumber"), formHelperText: "Votre num\u00E9ro de t\u00E9l\u00E9phone n'est pas valide", onChange: handleFormChange, required: true }), additionalFields, _jsx(FormInput, { name: "message", label: "Message", value: message, error: showError("message"), formHelperText: "La taille maximal du message est 2000 caract\u00E8res", onChange: handleFormChange, maxLength: 2000, required: false }), (subjectForm === SubjectForm.ESTABLISHMENT ||
                                subjectForm === SubjectForm.ESTABLISHMENT_DETAIL ||
                                subjectForm === SubjectForm.PARTNERSHIP) && (_jsx(Grid, { item: true, sx: { mt: 2 }, children: _jsx(FormLabel, { sx: { fontStyle: "italic" }, children: "Si vous faites une demande ou transmettez des informations pour un tiers, merci de veiller \u00E0 le faire avec l\u2019autorisation de la personne concern\u00E9e." }) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { sx: { pt: 2 }, children: _jsx(FormLabel, { children: subjectForm === SubjectForm.ESTABLISHMENT ||
                                            subjectForm === SubjectForm.ESTABLISHMENT_DETAIL ||
                                            subjectForm === SubjectForm.PARTNERSHIP ||
                                            subjectForm === SubjectForm.ARPAVIE_DOM_SERVICES ? (consentText[subjectForm]) : (_jsxs(_Fragment, { children: ["Les donn\u00E9es transmises dans le formulaire pourront \u00EAtre utilis\u00E9es par ARPAVIE pour vous contacter et vous adresser des communications de nature commerciale. Pour plus d'informations sur le traitement des donn\u00E9es, consultez notre", " ", _jsx(Link, { href: "/donnees-personnelles-et-cookies", target: "_blank", children: "Politique de confidentialit\u00E9 et de protection des donn\u00E9es personnelles" }), "."] })) }) }) }), _jsxs(Grid, { container: true, justifyContent: "center", xs: 12, children: [_jsx(Box, { id: "li-antibot", sx: { margin: "auto" } }), loaderCaptcha ? (_jsx(CircularProgress, {})) : (_jsx(Box, { sx: { "& #li-antibot": { width: "435px", height: "280px" } }, children: checkCaptchaStatus === CheckCaptchaStatus.to_validate && (_jsx(FormHelperText, { sx: { textAlign: "center", color: "colors.rouge_clair" }, children: "Veuillez valider le captcha" })) }))] }), _jsxs(Grid, { container: true, direction: "column", alignItems: "flex-end", sx: {
                                    position: "relative",
                                    mt: { xs: "6px", sm: 0 },
                                    pb: { xs: "8px", sm: 0 },
                                }, children: [_jsxs(Grid, { item: true, sx: { mt: 4 }, children: [handleReturn && (_jsx(Button, { variant: "outlined", onClick: handleReturn, children: "Retour" })), _jsx(Tooltip, { title: antibotResult === AntibotResult.blacklist
                                                    ? "Votre connexion est consid\u00E9r\u00E9e \u00E0 risque, par cons\u00E9quent vous ne pouvez pas acc\u00E9der \u00E0 nos services.\n                            Pour d\u00E9bloquer votre acc\u00E8s, adressez un mail \u00E0 : communication@arpavie.fr"
                                                    : "", children: _jsx(Box, { component: "span", children: _jsxs(Button, { variant: "contained", type: "submit", id: "captcha-check-button", disabled: antibotResult === AntibotResult.blacklist, sx: { ml: 2 }, children: [_jsx(Box, { children: "Envoyer" }), _jsx(Box, { sx: { display: { xs: "none", sm: "block" } }, children: "\u00A0ma demande" })] }) }) })] }), sendError && (_jsxs(FormHelperText, { error: true, sx: { fontSize: "1rem", display: "flex" }, children: [_jsx(ErrorIcon, {}), "\u00A0", sendError] })), _jsx(Typography, { variant: "label_mini", sx: { position: "absolute", left: 18, bottom: -10 }, children: "* champs obligatoires" })] })] }) })) }) })) : (_jsxs(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "space-evenly", sx: { minHeight: "500px", minWidth: { xs: " 350px", sm: "400px" } }, children: [_jsx(CheckCircleIcon, { sx: { color: "colors.bleu", fontSize: "2rem" } }), _jsx(Typography, { variant: "h5", sx: { fontSize: "16px", fontWeight: "600" }, children: "Votre message a bien \u00E9t\u00E9 envoy\u00E9" }), _jsx(Button, { variant: "outlined", onClick: handleButtonAction, sx: { mt: 2 }, children: buttonLabel })] })) }));
};
export default ContactForm;
