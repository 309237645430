import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
var SearchCTA = function (props) {
    var label = props.label, placeholder = props.placeholder, value = props.value, openModal = props.openModal, onClear = props.onClear;
    return (_jsxs(Button, { sx: {
            display: "flex",
            flexDirection: "row",
            height: "auto",
            p: { xs: "9px 0 9px 20px", md: "8px 32px 0" },
            m: { xs: "10px", md: "-8px 20px 0" },
            bgcolor: { xs: "colors.blanc", md: "colors.gris_bg", lg: "colors.blanc" },
            border: "none",
            boxShadow: value ? "1px 1px 24px rgb(0 0 0 / 22%)" : "none",
            borderRadius: "30px",
            "& > svg": {
                display: value ? "block" : "none",
                position: { md: "absolute" },
                right: 20,
                top: 17,
                fontSize: "2rem",
                p: "6px",
                color: "colors.noir",
                bgcolor: "colors.gris_5",
                borderRadius: "40px",
            },
            "&:hover:not(.Mui-disabled)": {
                bgcolor: "colors.recherche_clair_2",
                borderRadius: "30px",
                boxShadow: "none",
            },
            "&:focus:not(.Mui-disabled)": {
                boxShadow: "1px 1px 24px rgb(0 0 0 / 22%)",
                borderRadius: "30px",
            },
        }, onClick: openModal, children: [_jsxs(Grid, { item: true, xs: 10, md: "auto", container: true, direction: "column", alignItems: "flex-start", children: [_jsx(Typography, { variant: "label", children: label }), _jsx(TextField, { id: placeholder, label: _jsx(Typography, { variant: "etiquette", children: placeholder }), variant: "standard", value: value || placeholder, type: "submit", InputProps: {
                            sx: {
                                "& > input": { textAlign: "start", cursor: "pointer" },
                                color: "colors.recherche_fonce",
                                "&:before": { borderBottom: "none" },
                                "&:after": { borderBottom: "none" },
                                "&:hover:not(.Mui-disabled):before": {
                                    borderBottom: "none",
                                },
                                "&:focus:not(.Mui-disabled):before": {
                                    borderBottom: "none",
                                },
                            },
                        }, InputLabelProps: { sx: { '&.Mui-focused, &[data-shrink="true"]': { display: "none" } } }, sx: { mt: "-12px", minWidth: "250px", cursor: "pointer" } })] }), value && onClear && _jsx(CloseIcon, { onClick: onClear })] }));
};
export default SearchCTA;
