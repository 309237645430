import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Glider from "react-glider";
import { Box, Typography } from "@mui/material";
import CTANavigationLeftIcon from "components/CTANavigationLeftIcon";
import CTANavigationRight from "components/CTANavigationRight";
import ItemCardComponent from "components/ItemCard";
var ArticlesCarousel = function (_a) {
    var articles = _a.articles, _b = _a.title, title = _b === void 0 ? "D’autres articles pourraient vous intéresser" : _b;
    React.useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("glider-js/glider.min.css");
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h2", sx: { color: "colors.noir", mb: 2, mt: { xs: 7, md: 13.5 } }, children: title }), _jsx(Box, { px: 3, children: Array.isArray(articles) && articles.length > 0 && (_jsx(Glider, { skipTrack: true, draggable: true, hasArrows: true, slidesToScroll: 1, slidesToShow: "auto", dragVelocity: 1.5, itemWidth: 400, exactWidth: true, iconLeft: _jsx(CTANavigationLeftIcon, { sx: { color: "colors.bleu_fonce" } }), iconRight: _jsx(CTANavigationRight, { sx: { color: "colors.bleu_fonce" } }), children: _jsx("div", { className: "grider-track", children: articles.map(function (article) { return (_jsx(Box, { mx: 3.8125, mb: { xs: 7, mb: 11 }, children: _jsx(ItemCardComponent, { showItemTitle: "Lire cet article", item: article, imagesAreInGallery: true }) }, article.id)); }) }) })) })] }));
};
export default ArticlesCarousel;
