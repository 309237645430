import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const Block = (props) => {
  const { sx, ...others } = props;

  return (
    <Box
      sx={{
        px: { xs: 2, md: 0 },
        py: 2,
        ...sx,
      }}
      {...others}
    />
  );
};

Block.propTypes = {
  sx: PropTypes.object,
};

Block.defaultProps = {
  sx: {},
};

export default Block;
