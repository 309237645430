import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiModal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import SimpleBar from "simplebar-react";

export const ModalActions = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        "&> *": {
          ml: 1,
        },
      }}
      {...props}
    />
  );
};

const Modal = (props) => {
  const { children, onClose, size, title, ...others } = props;

  return (
    <MuiModal onClose={onClose} {...others}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
        onClick={onClose}
      >
        <Paper
          onClick={(e) => e.stopPropagation()}
          sx={(theme) => ({
            width: `${size && theme.breakpoints.values[size] ? `${theme.breakpoints.values[size]}px` : "auto"}`,
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: "auto",
            borderRadius: "7px",
            "& .simplebar-track.simplebar-vertical .simplebar-scrollbar:before ": {
              bgcolor: theme.palette.layout ? "layout.scrollbar" : "inherit",
            },
          })}
        >
          <SimpleBar style={{ maxHeight: "90vh" }}>
            {title && (
              <>
                <Box pl={2}>
                  <h2>{title}</h2>
                </Box>
                <Divider />
              </>
            )}
            <Box p={2}>{children}</Box>
          </SimpleBar>
        </Paper>
      </Stack>
    </MuiModal>
  );
};

Modal.propTypes = {
  ...MuiModal.propTypes,
  children: PropTypes.node,
  title: PropTypes.string,
};

Modal.defaultProps = {
  title: null,
  children: null,
  size: "md",
};

export default Modal;
