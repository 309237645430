var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
export var withGoogleMapWrapper = function (Component) {
    var WithGoogleMapWrapper = function (props) {
        var _a = React.useState(), apiKey = _a[0], setApiKey = _a[1];
        React.useEffect(function () {
            getEnvironmentPromise().then(function (env) {
                setApiKey(env.googleMapsApiKey);
            });
        }, []);
        if (!apiKey) {
            return null;
        }
        return (_jsx(Wrapper, { apiKey: apiKey, children: _jsx(Component, __assign({}, props)) }));
    };
    return WithGoogleMapWrapper;
};
