var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import axios from "axios";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { debounce } from "cms/utils/commonUtils";
export var summaryTitle = "Lieu de résidence";
var commune_api_url = "https://geo.api.gouv.fr/communes";
var SearchAutocompleteCity = function (props) {
    var form = props.form, setForm = props.setForm, closePopper = props.closePopper;
    var city = form.city;
    var _a = React.useState(city), searchAddressValue = _a[0], setSearchAddressValue = _a[1];
    var _b = React.useState([]), addressSuggestions = _b[0], setAddressSuggestions = _b[1];
    var _c = React.useState(false), searchAddressLoading = _c[0], setSearchAddressLoading = _c[1];
    var handleChangeSearchAddressValue = function (e, newValue, reason) {
        if (reason === "reset") {
            return;
        }
        setSearchAddressValue(newValue);
        if (newValue.length > 2) {
            setSearchAddressLoading(true);
            var searchType_1 = "nom";
            if (/^\d+$/.test(newValue)) {
                searchType_1 = "codePostal";
            }
            debounce(function () {
                var _a;
                return axios
                    .get(commune_api_url, {
                    params: (_a = {},
                        _a[searchType_1] = newValue,
                        _a.format = "geojson",
                        _a),
                })
                    .then(function (response) {
                    var _a, _b;
                    setSearchAddressLoading(false);
                    setAddressSuggestions((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) {
                        var _a, _b, _c, _d, _e, _f;
                        if (((_b = (_a = a.properties) === null || _a === void 0 ? void 0 : _a.nom) === null || _b === void 0 ? void 0 : _b.charAt(0)) === ((_d = (_c = b.properties) === null || _c === void 0 ? void 0 : _c.nom) === null || _d === void 0 ? void 0 : _d.charAt(0))) {
                            return (_e = a.properties) === null || _e === void 0 ? void 0 : _e.nom.localeCompare((_f = b.properties) === null || _f === void 0 ? void 0 : _f.nom);
                        }
                        else {
                            return a;
                        }
                    }));
                });
            }, 500)();
        }
        else {
            setAddressSuggestions([]);
        }
    };
    var handleChangeAddress = function (e, newAddress) {
        var properties = newAddress.properties, geometry = newAddress.geometry;
        var nom = properties.nom;
        var coordinates = geometry.coordinates;
        var lng = coordinates[0], lat = coordinates[1];
        setForm(__assign(__assign({}, form), { city: nom, lat: lat, lng: lng }));
        setSearchAddressValue(nom);
        closePopper && closePopper();
    };
    var showLocation = function (positionNav) {
        var _a = positionNav.coords, latitude = _a.latitude, longitude = _a.longitude;
        axios
            .get(commune_api_url, {
            params: {
                lat: latitude,
                lon: longitude,
            },
        })
            .then(function (response) {
            var nom = ((response === null || response === void 0 ? void 0 : response.data[0]) || {}).nom;
            setForm(__assign(__assign({}, form), { city: nom, lat: latitude, lng: longitude }));
            setSearchAddressValue(nom);
        });
    };
    var errorHandler = function (err) {
        if (err.code === 1) {
            // access Denied
            alert("Désolé, cette page n'est pas autorisée à accéder à votre géolocalisation");
        }
        else if (err.code === 2) {
            // Position unvailable
            alert("Désolé, la géolocalisation a échoué");
        }
        else if (err.code === 3) {
            // Timeout
            alert("Désolé, le temps imparti pour vous géolocaliser est dépassé");
        }
    };
    // Obtenir la localisation depuis le naviagteur
    var getLocation = function () {
        if (navigator.geolocation) {
            // timeout at 60000 milliseconds (60 seconds)
            var options = { timeout: 60000 };
            navigator.geolocation.getCurrentPosition(showLocation, errorHandler, options);
        }
        else {
            alert("Désolé, votre navigateur ne prend pas en charge la géolocalisation");
        }
    };
    return (_jsxs(Grid, { item: true, container: true, children: [_jsx(Grid, { item: true, xs: 10, md: 7, children: _jsx(Autocomplete, { onChange: handleChangeAddress, inputValue: searchAddressValue, onInputChange: handleChangeSearchAddressValue, options: addressSuggestions, filterOptions: function (o) { return o; }, getOptionLabel: function (opt) { var _a, _b; return "".concat((_a = opt === null || opt === void 0 ? void 0 : opt.properties) === null || _a === void 0 ? void 0 : _a.nom, " (").concat((_b = opt === null || opt === void 0 ? void 0 : opt.properties) === null || _b === void 0 ? void 0 : _b.codeDepartement, ")") || ""; }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: "Localisation", helperText: "Commune ou Code postal", variant: "standard", autoFocus: true, InputLabelProps: {
                            sx: {
                                '&.Mui-focused, &[data-shrink="true"]': { display: "none" },
                            },
                        }, sx: {
                            "& input": { fontSize: "1.334rem" },
                            pr: { xs: "10px", md: "50px" },
                            "&.MuiSvgIcon-root": { display: "none" },
                        } }))); }, noOptionsText: searchAddressValue && searchAddressValue !== city
                        ? "Aucune ville trouvée"
                        : "Veuillez saisir une ville ou un code postal", loading: searchAddressLoading, loadingText: "Recherche en cours..." }) }), _jsxs(Grid, { item: true, xs: 1, md: 5, onClick: getLocation, sx: {
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    xs: { px: "10px" },
                    md: { pl: "50px" },
                }, children: [_jsx(Typography, { sx: {
                            fontWeight: "600",
                            textDecoration: "underline",
                            mr: "4px",
                            color: "colors.bleu_clair",
                            display: { xs: "none", md: "block" },
                        }, children: "Me localiser" }), _jsx(MyLocationIcon, { sx: { color: "colors.cyan" } })] })] }));
};
export default SearchAutocompleteCity;
