var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Typography, Grid } from "@mui/material";
import Box from "@mui/system/Box";
import Image, { ImageContainer } from "cms/editableComponents/Image";
var PresentationBox = function (_a) {
    var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.image, image = _c === void 0 ? null : _c, _d = _a.title, title = _d === void 0 ? null : _d, _e = _a.dynamicPartBeforeDescription, dynamicPartBeforeDescription = _e === void 0 ? null : _e, _f = _a.description, description = _f === void 0 ? null : _f, _g = _a.descriptionLarge, descriptionLarge = _g === void 0 ? false : _g, _h = _a.dynamicPartAfterDescription, dynamicPartAfterDescription = _h === void 0 ? null : _h, _j = _a.backgroundColor, backgroundColor = _j === void 0 ? "#F7F7F7" : _j, _k = _a.titleColor, titleColor = _k === void 0 ? "inherit" : _k, _l = _a.descriptionColor, descriptionColor = _l === void 0 ? "inherit" : _l, _m = _a.buttonComponent, buttonComponent = _m === void 0 ? null : _m, others = __rest(_a, ["id", "image", "title", "dynamicPartBeforeDescription", "description", "descriptionLarge", "dynamicPartAfterDescription", "backgroundColor", "titleColor", "descriptionColor", "buttonComponent"]);
    return (_createElement(Grid, __assign({ container: true, sx: { bgcolor: backgroundColor } }, others, { key: id }),
        image && (_jsx(Grid, { item: true, xs: 12, md: 5, sx: { height: { md: 530 } }, children: _jsx(Box, { sx: { height: "100%" }, children: image.url ? (_jsx(ImageContainer, { ratio: "", children: _jsx(Image, { file: { url: image.url }, alt: image.alt || "", title: image.title || "", disableLazy: true }) })) : (_jsx(ImageContainer, { ratio: "", children: image })) }) })),
        _jsx(Grid, { item: true, xs: 12, md: 7, sx: { pl: { xs: 2, md: 8 }, pr: { xs: 2, md: 4.5 }, pt: { xs: 2, md: 4.375 }, pb: { xs: 2, md: 5 } }, children: _jsxs(Grid, { sx: { height: "100%" }, container: true, direction: "column", justifyContent: "space-between", children: [_jsxs(Grid, { item: true, children: [title && (_jsx(Typography, { variant: "h2", sx: { color: titleColor }, children: title })), dynamicPartBeforeDescription, description && (_jsx(Box, { sx: { mt: { xs: 3, md: 9.75 }, mb: { xs: 3, md: 9.75 } }, children: descriptionLarge ? (_jsx(Typography, { variant: "telephone", sx: { color: descriptionColor }, children: description })) : (_jsx(Box, { sx: { color: descriptionColor }, children: description })) })), dynamicPartAfterDescription] }), _jsx(Grid, { item: true, children: buttonComponent })] }) })));
};
export default PresentationBox;
