import React from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";
import { Box } from "@mui/system";

const componentClassName = "Da-Text";

const sanitizerOptions = {
  ...sanitizeHtml.defaults,
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h2", "img", "u", "s", "sub", "address", "sup"]),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    "*": ["id", "class", "style", "title"],
    a: ["href", "name", "target"],
    img: ["src", "target", "alt"],
  },
  allowedSchemesByTag: {
    ...sanitizeHtml.defaults.allowedSchemesByTag,
    img: ["https", "data"],
  },
};

const Text = (props) => {
  const { children, ...others } = props;

  const childrenProps = {};
  if (typeof children === "string") {
    childrenProps.dangerouslySetInnerHTML = { __html: sanitizeHtml(decodeURIComponent(children), sanitizerOptions) };
  } else {
    childrenProps.children = children;
  }

  return (
    <Box
      sx={(theme) => ({
        color: "primary.dark",
        "& h2": {
          fontWeight: 600,
          fontSize: "2rem",
          lineHeight: "2.43rem",
          letterSpacing: "0.6px",
          mb: 3,
          mt: 0,
        },
        "& h3": {
          fontSize: "1.57rem",
          fontWeight: 600,
          my: 1,
          lineHeight: "2.43rem",
          letterSpacing: "0px",
          mb: 3,
          mt: 0,
        },
        "& h4": {
          fontSize: "1.15rem",
          fontWeight: 600,
          my: 1,
          lineHeight: "1.71rem",
          letterSpacing: "0px",
          mb: 3,
          mt: 0,
        },
        "& h5": {
          fontSize: "1.42rem",
          lineHeight: "1.85rem",
          fontWeight: 600,
        },
        "& a": {
          color: "primary.main",
          fontWeight: 600,
          textDecoration: "none",
        },
        "& p": {
          wordBreak: "break-word",
        },
        "& pre": {
          whiteSpace: "pre-wrap",
        },
        "&  ul": {
          listStyle: "none",
          pl: 2,
          "& li": {
            textIndent: "-16px",
            "&:before": {
              content: `"\\f111"`,
              fontWeight: "600",
              fontSize: "8px",
              verticalAlign: "middle",
              textIndent: "0",
              width: "16px",
              display: "inline-block",
              fontFamily: `"Font Awesome 5 Pro"`,
              color: "secondary.main",
            },
            "& ul": {
              ml: 3,
              "& li": {
                textIndent: "-22px",
                "&:before": {
                  content: `"\\f00c"`,
                  fontWeight: "400",
                  fontSize: "14px",
                  width: "22px",
                  color: "secondary.main",
                },
              },
            },
          },
        },
        ...theme.text,
      })}
      className={componentClassName}
      {...childrenProps}
      {...others}
    />
  );
};

Text.propTypes = {
  children: PropTypes.node,
};

Text.defaultProps = {
  children: null,
};

export default Text;
