import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Tooltip, Typography } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
var FeatureItem = function (props) {
    var feature = props.feature, _a = props.bgcolor, bgcolor = _a === void 0 ? "transparent" : _a, _b = props.color, color = _b === void 0 ? "colors.cyan_fonce" : _b, _c = props.borderStyle, borderStyle = _c === void 0 ? "solid" : _c, _d = props.borderWidth, borderWidth = _d === void 0 ? "2px" : _d, borderColor = props.borderColor, _e = props.alignItems, alignItems = _e === void 0 ? "center" : _e;
    var name = feature.name, icon = feature.icon, description = feature.description;
    return (_jsxs(Grid, { container: true, direction: "column", alignItems: alignItems, children: [_jsx(Grid, { item: true, sx: {
                    bgcolor: bgcolor,
                    borderWidth: borderWidth,
                    borderStyle: borderStyle,
                    borderColor: borderColor || color,
                    color: color,
                    height: 58,
                    width: 58,
                    borderRadius: "50%",
                    lineHeight: "58px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    fontSize: 30,
                }, children: _jsx(Icon, { icon: icon || "concierge-bell" }) }), name && (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: description, children: _jsxs(Typography, { sx: {
                            textAlign: "center",
                            mt: 1,
                            color: "colors.noir",
                            textDecoration: "underline",
                            display: "inline-block",
                            wordBreak: "break-word",
                            fontWeight: 600,
                            "& > i": {
                                color: "colors.bleu",
                                pl: 1,
                            },
                        }, children: [name, description && _jsx(Icon, { icon: "info-circle", type: "fas" })] }) }) }))] }));
};
export default FeatureItem;
