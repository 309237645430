import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
import Tag from "components/Tag";
import { getEstablishmentTypeLabel, getEstablishmentIcon } from "utils/establishmentUtils";
import { EstablishmentFinancialAid } from "types/establishment/establishment-financial-aid.enum";
var EstablishmentTags = function (_a) {
    var establishment = _a.establishment, bgcolor = _a.bgcolor, borderColor = _a.borderColor;
    var type = establishment.type, availablesFinancialAids = establishment.availablesFinancialAids, isAvailable = establishment.isAvailable;
    var sx = {};
    if (bgcolor) {
        sx.bgcolor = bgcolor;
    }
    if (borderColor) {
        sx.borderColor = borderColor;
        sx.color = borderColor;
    }
    return (_jsxs(_Fragment, { children: [type && (_jsx(Box, { sx: { mb: 1 }, children: _jsx(Tag, { Icon: _jsx(Icon, { icon: getEstablishmentIcon(type) }), sx: sx, children: getEstablishmentTypeLabel(type) }) })), availablesFinancialAids &&
                (availablesFinancialAids.includes(EstablishmentFinancialAid.ASD) ||
                    availablesFinancialAids.includes(EstablishmentFinancialAid.PARTIAL_ASD)) && (_jsx(Box, { sx: { ml: 2, mb: 1 }, children: _jsx(Tag, { Icon: _jsx(Icon, { icon: "check" }), sx: sx, children: "\u00C9ligible \u00E0 l'aide sociale" }) })), isAvailable && (_jsx(Box, { sx: { ml: 2, mb: 1 }, children: _jsx(Tag, { Icon: _jsx(Icon, { icon: "user-check" }), sx: sx, children: "Places disponibles" }) }))] }));
};
export default EstablishmentTags;
