var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImageContainer } from "cms/editableComponents/Image";
import { Paper, Typography, Box } from "@mui/material";
var SpeechCard = function (_a) {
    var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.title, title = _c === void 0 ? "" : _c, _d = _a.speech, speech = _d === void 0 ? "" : _d, _e = _a.photo, photo = _e === void 0 ? null : _e, _f = _a.name, name = _f === void 0 ? "" : _f, _g = _a.position, position = _g === void 0 ? "" : _g, others = __rest(_a, ["id", "title", "speech", "photo", "name", "position"]);
    return (_jsxs(Paper, { square: true, sx: {
            borderRadius: 1,
            px: 3.5,
            pt: 2.5,
            pb: 5.75,
        }, children: [_jsx(Typography, { component: "h2", variant: "h3", sx: { mb: 3.5 }, children: title }), _jsx(Typography, { variant: "telephone", children: speech }), _jsx(Box, { sx: { mb: 2.375, display: "flex", justifyContent: "center" }, children: _jsx(Box, { sx: {
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        overflow: "hidden",
                    }, children: _jsx(ImageContainer, { ratio: "", children: photo }) }) }), _jsxs(Box, { sx: { textAlign: "center" }, children: [_jsx(Typography, { variant: "subtitle1", children: name }), _jsx(Box, { children: position })] })] }));
};
export default SpeechCard;
