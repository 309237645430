import React from "react";
import { hydrateRoot } from "react-dom/client";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import merge from "lodash.merge";
import { BrowserRouter as Router } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import { SiteContextProvider } from "cms/components/SiteContext";
// eslint-disable-next-line import/no-unresolved
import clientFront from "clientFront";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import createEmotionCache from "cms/front-office/createEmotionCache";
import App from "./App";

clientFront();

const cache = createEmotionCache();
const hookQueryClientOptions = CmsHooks.getHook(HOOKS.clientFront_queryClientOptions) || {};
const queryClientOptions = merge(
  {},
  {
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  },
  hookQueryClientOptions,
);
const queryClient = new QueryClient(queryClientOptions);

const dehydratedState = window.__REACT_QUERY_STATE__;

function Main() {
  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <CacheProvider value={cache}>
          <Router>
            <SiteContextProvider>
              <App />
            </SiteContextProvider>
          </Router>
        </CacheProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}

hydrateRoot(document.querySelector("#root"), <Main />);
