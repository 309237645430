var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { SearchParamsAvailabilityAdvanced } from "types/search-params-availability-advanced.enum";
export var summaryTitle = "Critères d'hébergement";
var SearchAdvancedAvailability = function (props) {
    var form = props.form, setForm = props.setForm;
    var medicalSupport = form.medicalSupport, advancedAvailability = form.advancedAvailability;
    var handleChangeRadio = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setForm(__assign(__assign({}, form), (_a = {}, _a[name] = value, _a)));
    };
    return (_jsx(Grid, { item: true, children: _jsx(FormControl, { disabled: !medicalSupport, sx: { ml: "56px" }, children: _jsx(RadioGroup, { name: "advancedAvailability", value: advancedAvailability, onChange: handleChangeRadio, children: Object.entries(SearchParamsAvailabilityAdvanced).map(function (_a) {
                    var advancedKey = _a[0], advancedLabel = _a[1];
                    return advancedKey !== "nightEmergencyReception" &&
                        advancedKey !== "ssiad" && (_jsx(FormControlLabel, { value: advancedKey, control: _jsx(Radio, {}), label: medicalSupport ? _jsx(Typography, { variant: "donnee", children: advancedLabel }) : advancedLabel }, advancedKey));
                }) }) }) }));
};
export default SearchAdvancedAvailability;
