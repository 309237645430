import React, { useContext } from "react";
import PropTypes from "prop-types";
import Page from "cms/front-office/components/Page";
import PageVersionContext from "cms/components/PageVersionContext";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
import { enhancePageVersionContents } from "cms/utils/templateUtils";

const PageSwitcher = (props) => {
  const { templatesSettings } = props;
  const { currentPageVersion, loading } = useContext(PageVersionContext);

  const NotFound = CmsHooks.getHook(HOOKS.PageSwitcher_404) || (() => "404 Not Found");

  if (!currentPageVersion) {
    if (!loading) {
      return <NotFound />;
    }
    return null;
  }

  let templateSettingsProps = {};
  if (templatesSettings) {
    const currentTemplateSettings = templatesSettings.children
      ? templatesSettings.children.find((c) => c.key === currentPageVersion.template)
      : null;
    templateSettingsProps = currentTemplateSettings
      ? generateTemplatePropsFromContents(currentTemplateSettings.children)
      : {};
  }

  return <Page page={enhancePageVersionContents(currentPageVersion)} templatesSettings={templateSettingsProps} />;
};

PageSwitcher.propTypes = {
  templatesSettings: PropTypes.shape(),
};

PageSwitcher.defaultProps = {
  templatesSettings: null,
};

export default PageSwitcher;
