import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import useEnvironment from "cms/back-office/hooks/useEnvironment";
export var MatomoContextProvider = function (_a) {
    var children = _a.children, disabled = _a.disabled;
    var _b = useEnvironment(), matomo_track_url = _b.matomo_track_url, matomo_site_id = _b.matomo_site_id;
    var _c = useState(null), instance = _c[0], setInstance = _c[1];
    useEffect(function () {
        if (!disabled && matomo_track_url && matomo_site_id) {
            var options = {
                urlBase: matomo_track_url,
                siteId: Number(matomo_site_id),
                configurations: {
                    disableCookies: true,
                },
            };
            setInstance(createInstance(options));
        }
    }, [matomo_track_url, matomo_site_id, disabled]);
    return (_jsx(MatomoProvider
    //@ts-ignore
    , { 
        //@ts-ignore
        value: instance, children: children }));
};
