import axios from "axios";

let promise = null;

export default () => {
  if (!promise) {
    promise = axios.get("/environment.json").then((r) => r.data);
  }
  return promise;
};
