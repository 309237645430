import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Paper, Typography, Link } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import ItemTags from "components/ItemTags";
import MultilineEllipsis from "components/MultilineEllipsis";
var ItemsCard = function (_a) {
    var itemsTitle = _a.itemsTitle, recentTitle = _a.recentTitle, itemsText = _a.itemsText, recentItems = _a.recentItems, _b = _a.tags, tags = _b === void 0 ? [] : _b, onClickTag = _a.onClickTag, _c = _a.selectedTags, selectedTags = _c === void 0 ? [] : _c, _d = _a.isTitle, isTitle = _d === void 0 ? false : _d;
    React.useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("simplebar-react/dist/simplebar.min.css");
        }
    }, []);
    var handleClickTag = function (tag) {
        if (onClickTag) {
            onClickTag(tag);
        }
    };
    return (_jsxs(Box, { sx: { mt: { xs: 7, md: 0 } }, children: [_jsxs(Paper, { square: true, sx: { borderRadius: 1, mb: 2.5, px: 2, py: 4 }, children: [_jsx(Typography, { component: isTitle ? "h1" : "h2", variant: "h2", sx: { mb: 1 }, children: itemsTitle }), _jsx(Typography, { variant: "subtitle1", children: itemsText })] }), _jsxs(Paper, { square: true, sx: { borderRadius: 1, px: 2, py: 4 }, children: [_jsx(Box, { sx: { mb: 0.5 }, children: _jsx(Typography, { variant: "onglet", children: recentTitle }) }), recentItems === null || recentItems === void 0 ? void 0 : recentItems.map(function (item) { return (_jsx(Box, { sx: { mt: 1.625 }, children: _jsx(Link, { component: HashLink, to: item.fullPath, children: _jsx(MultilineEllipsis, { limit: 1, title: item.title, children: item.title }) }) }, item.id)); })] }), tags.length > 0 && (_jsxs(Paper, { square: true, sx: { borderRadius: 1, px: 2, py: 4, mt: 2.5 }, children: [_jsx(Typography, { variant: "onglet", children: "Sujets" }), _jsx(Box, { sx: { mt: 1 }, children: tags.length > 0 ? (_jsx(ItemTags, { tags: tags, onClickTag: handleClickTag, selectedTags: selectedTags })) : (_jsx(Typography, { variant: "subtitle1", children: "Aucun sujet" })) })] }))] }));
};
export default ItemsCard;
