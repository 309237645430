var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import ContactForm from "components/ContactForm";
import { SubjectForm } from "types/subject-fom.enum";
import { FormLabel, FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox, Typography, } from "@mui/material";
var ServicesContact = function (props) {
    var onClose = props.onClose, handleReturn = props.handleReturn;
    var AUTONOMY_ASSISTANCE = "Aide à l'autonomie";
    var HOME_MAINTENANCE_ASSISTANCE = "Aide à l'entretien de mon domicile";
    var HOSPITAL_DISCHARGE_ASSISTANCE = "Assistance sortie d'hospitalisation";
    var PLUS_SERVICES = "Services +";
    var _a = useState([]), typologies = _a[0], setTypologies = _a[1];
    var _b = useState(false), formSubmitted = _b[0], setFormSubmitted = _b[1];
    var error = formSubmitted && typologies.length === 0;
    var closeModal = function () {
        onClose();
    };
    var handleChange = function (event) {
        var _a = event.target, name = _a.name, checked = _a.checked;
        if (checked) {
            setTypologies(__spreadArray(__spreadArray([], typologies, true), [name], false));
        }
        else {
            setTypologies(typologies.filter(function (typology) { return typology !== name; }));
        }
    };
    var typologiesField = (_jsxs(FormControl, { error: error, sx: { width: "100%", pt: 3, pl: 2 }, children: [_jsx(Typography, { variant: "label", component: FormLabel, sx: { mb: "6px" }, children: "Typologie(s) de services *" }), _jsxs(FormGroup, { row: true, children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { sx: {
                                color: error ? "colors.rouge_clair" : "inherit",
                            }, checked: !!typologies.find(function (typology) { return typology === AUTONOMY_ASSISTANCE; }), onChange: handleChange, name: AUTONOMY_ASSISTANCE }), label: AUTONOMY_ASSISTANCE }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { sx: {
                                color: error ? "colors.rouge_clair" : "inherit",
                            }, checked: !!typologies.find(function (typology) { return typology === HOME_MAINTENANCE_ASSISTANCE; }), onChange: handleChange, name: HOME_MAINTENANCE_ASSISTANCE }), label: HOME_MAINTENANCE_ASSISTANCE }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { sx: {
                                color: error ? "colors.rouge_clair" : "inherit",
                            }, checked: !!typologies.find(function (typology) { return typology === HOSPITAL_DISCHARGE_ASSISTANCE; }), onChange: handleChange, name: HOSPITAL_DISCHARGE_ASSISTANCE }), label: HOSPITAL_DISCHARGE_ASSISTANCE }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { sx: {
                                color: error ? "colors.rouge_clair" : "inherit",
                            }, checked: !!typologies.find(function (typology) { return typology === PLUS_SERVICES; }), onChange: handleChange, name: PLUS_SERVICES }), label: PLUS_SERVICES })] }), error && _jsx(FormHelperText, { children: "Choissisez au moins une typologie de services" })] }, "Typologie(s) de services"));
    return (_jsx(ContactForm, { overrideDefaultForm: { subject: SubjectForm.ARPAVIE_DOM_SERVICES }, buttonLabel: "Fermer la fen\u00EAtre", handleButtonAction: closeModal, formSubmitted: formSubmitted, handleFormSubmitted: function (bool) { return setFormSubmitted(bool); }, handleReturn: handleReturn, optionalFields: ["message"], additionalFields: [typologiesField], additionalFieldsValues: [{ name: "servicesTypologies", value: typologies.join(", ") }], additionalFieldsError: typologies.length === 0, subjectForm: SubjectForm.ARPAVIE_DOM_SERVICES }));
};
export default ServicesContact;
