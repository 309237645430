import scrollIntoView from "scroll-into-view-if-needed";
export var scrollSent = function (id) {
    var titleElement = document.getElementById(id);
    if (titleElement) {
        scrollIntoView(titleElement, {
            block: "start",
            inline: "start",
        });
    }
};
