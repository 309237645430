import React from "react";
import PropTypes from "prop-types";
import { HashLink } from "react-router-hash-link";
import { ImageContainer } from "cms/editableComponents/Image";
import { alpha, Button, Grid, Typography, Paper } from "@mui/material";
import Box from "@mui/system/Box";

const ImageBanner = (props) => {
  const { page } = props;
  const { image, contents } = page;
  const { subtitle, firstCustomCard, secondCustomCard, thirdCustomCard } = contents;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          mb: { xs: 0, md: 1 },
          height: { xs: 390, lg: 1016 },
        }}
      >
        {image && <ImageContainer ratio="">{image}</ImageContainer>}
      </Box>
      <Box
        sx={{
          position: { lg: "absolute" },
          bottom: "26px",
          left: 0,
          right: 0,
        }}
      >
        <Paper
          sx={(theme) => ({
            bgcolor: alpha(theme.palette.colors?.cyan, 0.9),
            pt: 1,
            height: 213,
            textAlign: "center",
          })}
        >
          <Typography
            variant="h2"
            sx={{
              color: "colors.noir",
              my: 3,
            }}
          >
            {subtitle}
          </Typography>
        </Paper>
        <Box sx={{ mt: { xs: -10, sm: -15 }, display: "flex", justifyContent: "center" }}>
          <Grid container columnSpacing={4} sx={{ width: { xs: "95%", xl: "83%" }, zIndex: 10 }}>
            <Grid item md={4} xs={12} sx={{ mb: { xs: 2, md: 0 } }}>
              {firstCustomCard}
            </Grid>
            <Grid item md={4} xs={12} sx={{ mb: { xs: 2, md: 0 } }}>
              {secondCustomCard}
            </Grid>
            <Grid item md={4} xs={12} sx={{ mb: { xs: 2, md: 0 } }}>
              {thirdCustomCard}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3.5, mb: { xs: 8, lg: 0 }, px: 2 }}>
          <Button
            variant="cta"
            sx={{
              height: "auto",
              px: 4.75,
              py: 2.5,
            }}
            component={HashLink}
            to="/recherche-detaillee"
          >
            Je ne sais pas quoi choisir
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ImageBanner.propTypes = {
  page: PropTypes.shape(),
};

ImageBanner.defaultProps = {
  page: {},
};

export default ImageBanner;
