import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
var InfosBlock = function (props) {
    var accordions = props.accordions;
    var _a = React.useState(""), currentExpanded = _a[0], setCurrentExpanded = _a[1];
    var handleChangePanel = function (title) { return function (event, isExpanded) {
        setCurrentExpanded(isExpanded ? title : "");
    }; };
    var alwaysOpen = accordions.length === 1;
    return (_jsx(_Fragment, { children: accordions.map(function (accordionInfo, id) {
            var title = accordionInfo.title, text = accordionInfo.text;
            var isExpanded = currentExpanded === title;
            return (_jsxs(Accordion, { expanded: alwaysOpen || isExpanded, disabled: alwaysOpen, onChange: handleChangePanel(title), children: [_jsx(AccordionSummary, { expandIcon: !alwaysOpen && _jsx(ExpandMoreIcon, {}), "aria-controls": "".concat(id, "-content"), id: "".concat(id, "-header"), children: _jsx(Typography, { variant: "onglet", children: title }) }), _jsx(AccordionDetails, { children: _jsx(Typography, { sx: { flex: "1" }, children: text }) })] }, title));
        }) }));
};
export default InfosBlock;
