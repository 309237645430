var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual } from "fast-equals";
import { withGoogleMapWrapper } from "components/GoogleMapWrapper";
var GoogleMap = function (_a) {
    var children = _a.children, style = _a.style, options = __rest(_a, ["children", "style"]);
    var ref = React.useRef(null);
    var _b = React.useState(), map = _b[0], setMap = _b[1];
    React.useEffect(function () {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);
    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(function () {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: ref, style: style }), React.Children.map(children, function (child) {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    //@ts-ignore
                    return React.cloneElement(child, { map: map });
                }
            })] }));
};
export default withGoogleMapWrapper(GoogleMap);
// @ts-ignore
var deepCompareEqualsForMaps = createCustomEqual(function (deepEqual) { return function (a, b) {
    if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    return deepEqual(a, b);
}; });
function useDeepCompareMemoize(value) {
    var ref = React.useRef();
    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
}
function useDeepCompareEffectForMaps(callback, dependencies) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
