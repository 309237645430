import contentsTypes from "cms/enums/contentsTypes";
import ArticleTemplate from "templates/article/ArticleTemplate";
import { Templates } from "types/templates.enum";
export default {
    key: Templates.ARTICLE,
    label: "Article",
    templateImage: "/newspaper-solid.svg",
    Component: ArticleTemplate,
    useTags: true,
    initialContents: [
        {
            key: "imagesGallery",
            type: contentsTypes.IMAGES_GALLERY,
            value: "",
            children: [
                {
                    key: "images",
                    type: contentsTypes.IMAGES,
                    value: "",
                    children: [
                        {
                            key: "image",
                            type: contentsTypes.IMAGE_AS_FILE,
                            value: "",
                        },
                    ],
                },
                {
                    key: "height",
                    type: contentsTypes.INPUT_NUMBER,
                    value: "315",
                },
                {
                    key: "infinite",
                    type: contentsTypes.CHECKBOX,
                    value: "true",
                },
                {
                    key: "autoPlay",
                    type: contentsTypes.CHECKBOX,
                    value: "false",
                },
                {
                    key: "slideInterval",
                    type: contentsTypes.INPUT_NUMBER,
                    value: "3000",
                },
                {
                    key: "showThumbnails",
                    type: contentsTypes.CHECKBOX,
                    value: "true",
                },
                {
                    key: "thumbnailPosition",
                    type: contentsTypes.SELECT,
                    value: "bottom",
                },
                {
                    key: "showNav",
                    type: contentsTypes.CHECKBOX,
                    value: "true",
                },
                {
                    key: "showIndex",
                    type: contentsTypes.CHECKBOX,
                    value: "false",
                },
                {
                    key: "showBullets",
                    type: contentsTypes.CHECKBOX,
                    value: "false",
                },
                {
                    key: "showPlayButton",
                    type: contentsTypes.CHECKBOX,
                    value: "false",
                },
                {
                    key: "showFullscreenButton",
                    type: contentsTypes.CHECKBOX,
                    value: "true",
                },
            ],
        },
        {
            key: "dynamicPart",
            type: contentsTypes.DYNAMIC,
            value: "",
        },
    ],
};
