import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "cms/editableComponents/Link";
import Icon from "cms/editableComponents/Icon";

const Attachment = (props) => {
  const { title, description, linkText, file, ...others } = props;
  const { name, url } = file || {};
  const { id, ...othersProps } = others;

  return (
    <Box
      sx={{
        position: "relative",
        p: 3,
        bgcolor: "secondary.light",
        "&:hover": {
          bgcolor: "secondary.main",
        },
        "& > a > *": {
          color: "primary.main",
          fontSize: "1.71rem",
        },
      }}
      {...othersProps}
    >
      {title && (
        <Typography variant="h5" sx={{ mb: description ? 1 : 2, mt: 0 }}>
          {title}
        </Typography>
      )}
      {description && <Typography sx={{ mb: 2.5 }}>{description}</Typography>}
      <div>
        {url ? (
          <Box
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            sx={{
              textDecoration: "none",
              color: "primary.main",
              fontWeight: "600",
              display: "inline-block",
              pt: 1,
            }}
            download={name || true}
          >
            {linkText}
          </Box>
        ) : (
          <Box
            sx={{
              color: "primary.main",
              fontWeight: "600",
              display: "inline-block",
              pt: 1,
            }}
          >
            {linkText}
          </Box>
        )}
      </div>
      <Link
        url={url}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
        external
      >
        <Icon icon="eye" title="Voir le document" />
      </Link>
    </Box>
  );
};

Attachment.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

Attachment.defaultProps = {
  title: null,
  linkText: null,
  description: null,
  file: {
    name: "",
    url: null,
  },
};

export default Attachment;
