var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { HashLink } from "react-router-hash-link";
import SimpleBar from "simplebar-react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import EstablishmentCard from "components/EstablishmentCard";
import EstablishmentsCarousel from "components/EstablishmentsCarousel";
import GoogleMapEstablishments from "components/GoogleMapEstablishments";
import AdvancedSearchFiltered from "routes/AdvancedSearchFiltered";
import { useEnhanceListWithPageVersions } from "services/pageversion.service";
import { useSearchEstablishments } from "services/search.service";
import { useDebounce } from "cms/utils/commonUtils";
import { getQueryParams, createQueryParams } from "cms/utils/urlUtils";
import { getEstablishmentFromEstablishmentResult, getEstablishmentSearchParamsFromQueries, getEstablishmentTypeFromSearchParams, } from "utils/establishmentSearchUtils";
import { useMatomo } from "@datapunt/matomo-tracker-react";
var HelmetSearch = function (_a) {
    var pathname = _a.pathname, title = _a.title, description = _a.description;
    return (_jsxs(Helmet, { children: [_jsx("title", { children: title }), _jsx("meta", { name: "description", content: description }), _jsx("meta", { property: "og:url", content: pathname }), _jsx("meta", { property: "og:title", content: title }), _jsx("meta", { property: "og:description", content: description }), _jsx("meta", { property: "og:type", content: "website" }), _jsx("link", { rel: "canonical", href: pathname })] }));
};
var GoToSearchBlock = function (_a) {
    var location = _a.location;
    return (_jsxs(Paper, { square: true, sx: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            py: 2,
            px: 4,
        }, children: [_jsx(Typography, { variant: "label", children: "Vous n\u2019\u00EAtes pas s\u00FBre de votre recherche ?" }), _jsx(Typography, { variant: "body1", sx: { my: 2 }, children: "Laissez-vous guider" }), _jsx(Button, { variant: "cta", component: HashLink, to: "/recherche-detaillee".concat(location.search), endIcon: _jsx(SearchIcon, { sx: {
                        color: "colors.noir",
                        bgcolor: "colors.blanc",
                        border: "1px solid",
                        borderColor: "colors.blanc",
                        borderRadius: "20px",
                    } }), children: "Trouver ma r\u00E9sidence en quelques clics" })] }));
};
var ResultSearchEstablishment = function (_a) {
    var location = _a.location, history = _a.history;
    var pathname = location.pathname, search = location.search;
    var queryParams = getQueryParams(search);
    var form = getEstablishmentSearchParamsFromQueries(queryParams);
    var trackPageView = useMatomo().trackPageView;
    var title = "Nos résidences";
    var description = "ARPAVIE propose et développe une offre étendue de solutions d'hébergement et de services liés à la prise en charge des personnes âgées et des personnes handicapées vieillissantes à travers ses établissements répartis sur l'ensemble du territoire français.";
    useEffect(function () {
        trackPageView({ documentTitle: title });
    }, [search, trackPageView]);
    var stayHome = form.stayHome, medicalSupport = form.medicalSupport, needAssist = form.needAssist, advancedAvailability = form.advancedAvailability, lat = form.lat, lng = form.lng, city = form.city, radius = form.radius, budgetMin = form.budgetMin, budgetMax = form.budgetMax, specificTypes = form.specificTypes, establishmentPrices = form.establishmentPrices, proximityElements = form.proximityElements, PMR = form.PMR;
    var handleChangeForm = function (newForm) {
        history.push({
            pathname: pathname,
            search: "?".concat(createQueryParams(__assign(__assign({}, queryParams), Object.entries(newForm).reduce(function (acc, _a) {
                var _b, _c;
                var key = _a[0], value = _a[1];
                if (typeof value === "boolean")
                    return __assign(__assign({}, acc), (_b = {}, _b[key] = value ? "1" : "0", _b));
                return __assign(__assign({}, acc), (_c = {}, _c[key] = value, _c));
            }, {})))),
        });
    };
    var type = getEstablishmentTypeFromSearchParams({
        stayHome: stayHome,
        medicalSupport: medicalSupport,
        needAssist: needAssist,
    });
    var geoloc = {
        type: type,
        advancedAvailability: advancedAvailability,
        lat: lat,
        lng: lng,
        radius: radius,
        specificTypes: specificTypes,
        establishmentPrices: establishmentPrices,
        proximityElements: proximityElements,
        PMR: PMR,
        from: 0,
        size: 200,
    };
    var budget = useMemo(function () { return ({ budgetMin: budgetMin, budgetMax: budgetMax }); }, [budgetMin, budgetMax]);
    var debouncedSearch = useDebounce(budget, 2000);
    var _b = useSearchEstablishments(__assign(__assign({}, debouncedSearch), geoloc)), _c = _b.data, establishmentsResults = _c === void 0 ? [] : _c, isFetchingEstablishments = _b.isFetching, errorEstablishments = _b.error;
    var _d = useEnhanceListWithPageVersions(establishmentsResults.map(getEstablishmentFromEstablishmentResult)), _e = _d.data, establishments = _e === void 0 ? [] : _e, isFetchingVersions = _d.isFetching, errorVersions = _d.error;
    establishments.sort(function (e1, e2) {
        var _a, _b;
        var city1 = (_a = e1 === null || e1 === void 0 ? void 0 : e1.address) === null || _a === void 0 ? void 0 : _a.city;
        var city2 = (_b = e2 === null || e2 === void 0 ? void 0 : e2.address) === null || _b === void 0 ? void 0 : _b.city;
        if (city1 === city2) {
            return 0;
        }
        if (city1 === city) {
            return -1;
        }
        if (city2 === city) {
            return 1;
        }
        return 0;
    });
    var isFetching = React.useMemo(function () { return isFetchingEstablishments || isFetchingVersions; }, [isFetchingEstablishments, isFetchingVersions]);
    var error = React.useMemo(function () { return errorEstablishments || errorVersions; }, [errorEstablishments, errorVersions]);
    React.useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("simplebar-react/dist/simplebar.min.css");
        }
    }, []);
    React.useEffect(function () {
        if (typeof sessionStorage !== "undefined") {
            sessionStorage.setItem("queryParams", location.search);
        }
    }, [location.search]);
    var resultsText = React.useMemo(function () {
        if (isFetching) {
            return "Recherche en cours...";
        }
        if (error) {
            return (_jsxs(_Fragment, { children: ["Le service de recherche est actuellement indisponible.", _jsx("br", {}), "Veuillez nous excuser pour la g\u00EAne occasionn\u00E9e."] }));
        }
        if (establishments.length === 0) {
            return "Aucune résidence trouvée.";
        }
        if (establishments.length === 1) {
            return "Une résidence correspond à vos critères";
        }
        return "".concat(establishments.length, " r\u00E9sidences correspondent \u00E0 vos crit\u00E8res");
    }, [error, establishments.length, isFetching]);
    return (_jsxs(Box, { px: { xs: 1, md: 3, lg: 5 }, py: 5, children: [_jsx(HelmetSearch, { pathname: pathname, title: title, description: description }), _jsx(AdvancedSearchFiltered, { form: form, setForm: handleChangeForm }), isFetching || error || establishments.length === 0 ? (_jsxs(_Fragment, { children: [_jsx(HelmetSearch, { pathname: pathname, title: title, description: description }), _jsxs(Grid, { container: true, direction: "column", alignItems: "center", children: [_jsx(Typography, { variant: "h2", sx: { my: 6, textAlign: "center" }, children: resultsText }), error ? (_jsx(Link, { component: HashLink, to: "/", children: "Revenir \u00E0 l'accueil" })) : null, !isFetching && !error && _jsx(GoToSearchBlock, { location: location })] })] })) : (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, lg: 7, order: { xs: 2, lg: 1 }, children: _jsx(Box, { sx: {
                                "& .simplebar-offset": { width: "100%" },
                                "& .simplebar-wrapper": { maxHeight: { lg: 1165, xl: 1100 } },
                            }, children: _jsx(SimpleBar, { children: _jsx(Grid, { container: true, spacing: 1.5, sx: { pr: { xs: 0.5, lg: 2.5 }, pl: 0.5, pb: 1 }, children: establishments.map(function (establishment) { return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 4, children: _jsx(EstablishmentCard, { establishment: establishment }) }, establishment.id)); }) }) }) }) }), _jsx(Grid, { item: true, xs: 12, lg: 5, order: { xs: 1, lg: 2 }, children: _jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(Paper, { square: true, sx: { px: 2, py: 4 }, children: [_jsx(Typography, { variant: "h1", children: "R\u00E9sultats de votre recherche" }), _jsx(Typography, { variant: "label", children: resultsText })] }) }), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h2", sx: { mb: 1 }, children: "Sur la carte" }), _jsx(Paper, { children: _jsx(GoogleMapEstablishments, { establishments: establishments }) })] }), _jsx(Grid, { item: true, xs: 12, sx: { display: { xs: "none", lg: "block" } }, children: _jsx(GoToSearchBlock, { location: location }) })] }) }), _jsx(Grid, { item: true, xs: 12, sx: { display: { lg: "none" } }, order: 3, children: _jsx(GoToSearchBlock, { location: location }) })] })), _jsx(Box, { sx: { mt: 20 }, children: _jsx(EstablishmentsCarousel, {}) })] }));
};
export default ResultSearchEstablishment;
