var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import Label from "components/Label";
import SearchEstablishmentType from "components/SearchEstablishmentType";
import SearchStep from "routes/Search/SearchStep";
import { SearchParamsAvailability } from "types/search-params-availability.enum";
export var summaryTitle = "Critères d'hébergement";
var CriteriaStep = function (props) {
    var form = props.form, setForm = props.setForm, handleValidateStep = props.handleValidateStep, nextStepButton = props.nextStepButton, exitLink = props.exitLink, showError = props.showError;
    var availability = form.availability;
    var handleChangeRadio = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setForm(__assign(__assign({}, form), (_a = {}, _a[name] = value, _a)));
    };
    return (_jsx(SearchStep, { title: summaryTitle, subtitle: "Quels sont vos besoins", button: nextStepButton, accordions: [
            {
                title: "Dans quel cas avoir recours à une équipe soignante sur place 24/24 ?",
                text: "Vous avez besoin d’une équipe soignante 24 heures sur 24 pour vous accompagner dans votre vie au quotidien. Nos maisons de retraite médicalisées, EHPAD, vous proposent une équipe pluridisciplinaire avec des spécialistes de la santé, une présence continue, des soins et des services complémentaires adaptés à vos besoins. Certaines résidences peuvent vous proposer des prises en charge spécifiques que vous pourrez sélectionner en 4ème étape.",
            },
            {
                title: "J’ai plus de 60 ans et je suis autonome, quelle solution d’hébergement est faite pour moi ?",
                text: (_jsxs(_Fragment, { children: ["Vous avez plus de 60 ans, vous \u00EAtes autonomes dans les actes de votre vie quotidienne et vous souhaitez un logement que vous pourrez personnaliser avec votre mobilier. Nos r\u00E9sidences autonomie ou services s\u00E9nior sont situ\u00E9es au c\u0153ur des villes ou proche du centre et des commerces. Une offre de services et de prestations vous seront propos\u00E9es pour garder une ind\u00E9pendance de vie identique \u00E0 celle d\u2019un domicile classique, tout en r\u00E9pondant aux probl\u00E8mes d'isolement et de s\u00E9curit\u00E9. Vous b\u00E9n\u00E9ficierez d\u2019un socle de prestations :", _jsxs("ul", { children: [_jsx("li", { children: "L\u2019acc\u00E8s \u00E0 un service de restauration," }), _jsx("li", { children: "L\u2019acc\u00E8s \u00E0 un service de blanchisserie," }), _jsx("li", { children: "L\u2019acc\u00E8s \u00E0 un dispositif de s\u00E9curit\u00E9 24h/24 et 7j/7," }), _jsx("li", { children: "L\u2019organisation de prestations d\u2019animation de la vie sociale" })] })] })),
            },
            {
                title: "Comment faire pour rester à mon domicile ?",
                text: "Vous constatez une diminution de votre mobilité ou de votre autonomie ou vous avez été hospitalisé et vous avez besoin d’aide ou d’accompagnement dans les actes de votre vie quotidienne (prise de repas, toilette, ménage, courses et préparation des repas, présence de nuit, promenades, …). Nos prestations d’aide à la personne sont disponibles 7 jours sur 7 et 24 heures sur 24.",
            },
        ], handleValidateStep: handleValidateStep, exitLink: exitLink, children: _jsxs(Grid, { item: true, xs: 12, md: 6, container: true, direction: "column", children: [_jsx(Label, { sx: { color: showError("availability") ? "colors.rouge_clair" : "inherit" }, children: "Dites-nous quelle est l'urgence de votre recherche" }), _jsx(RadioGroup, { row: true, name: "availability", value: availability, onChange: handleChangeRadio, children: Object.entries(SearchParamsAvailability).map(function (_a) {
                        var availabilityKey = _a[0], availabilityLabel = _a[1];
                        return (_jsx(FormControlLabel, { value: availabilityKey, control: _jsx(Radio, {}), label: _jsx(Typography, { variant: "donnee", children: availabilityLabel }) }, availabilityKey));
                    }) }), _jsx(Typography, { variant: "erreur", sx: { color: "colors.rouge_clair" }, children: showError("availability") && "Champ obligatoire" }), _jsx(Box, { sx: { mt: "75px" }, children: _jsx(Label, { sx: { color: showError("type") ? "colors.rouge_clair" : "inherit" }, children: "S\u00E9lectionnez vos besoins" }) }), _jsx(SearchEstablishmentType, { form: form, setForm: setForm, showError: showError, advanced: true })] }) }));
};
export default CriteriaStep;
