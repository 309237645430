var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "cms/back-office/components/Loader";
import { Grid, Box, Typography } from "@mui/material";
import ItemCardComponent from "components/ItemCard";
import axios from "axios";
import SiteContext from "cms/components/SiteContext";
import ItemsCard from "components/ItemsCard";
import { Templates } from "types/templates.enum";
import InfiniteScroll from "react-infinite-scroller";
import { createQueryParams, getQueryParams } from "cms/utils/urlUtils";
import { useArticlesTagsByParent } from "services/article.service";
var ArticlesTemplate = function (_a) {
    var pageVersion = _a.page;
    var page = pageVersion.page;
    var pageId = page.id;
    var site = useContext(SiteContext);
    var _b = useLocation(), pathname = _b.pathname, search = _b.search;
    var history = useHistory();
    var queryParams = getQueryParams(search) || {};
    var parentPageId = queryParams.parentId, title = queryParams.title;
    var size = 25;
    var _c = useState([]), articles = _c[0], setArticles = _c[1];
    var _d = useState(0), articlesNumber = _d[0], setArticlesNumber = _d[1];
    var _e = useState(true), hasMoreResults = _e[0], setHasMoreResults = _e[1];
    var _f = useState(true), isLoading = _f[0], setIsLoading = _f[1];
    var tags = useArticlesTagsByParent(parentPageId || pageId, site).data;
    var loadResults = useCallback(function (page) {
        if (page === 1 || hasMoreResults) {
            setIsLoading(true);
            var filter = ["page.parentId||eq||".concat(parentPageId || pageId)];
            if (queryParams.tag)
                filter.push("tags||cont||".concat(queryParams.tag));
            axios
                .get("/findPagesByTemplates", {
                params: {
                    filter: filter,
                    templates: Templates.ARTICLE,
                    siteId: site === null || site === void 0 ? void 0 : site.id,
                    sortBy: "publishedDate",
                    per_page: size,
                    offset: (page - 1) * size,
                    sortOrder: "DESC",
                },
            })
                .then(function (response) {
                var _a = response.data.total, total = _a === void 0 ? 0 : _a;
                var _b = response.data.data, data = _b === void 0 ? [] : _b;
                if (title) {
                    data = data.map(function (article) {
                        var fullPath = article.fullPath;
                        return __assign(__assign({}, article), { fullPath: "".concat(fullPath).concat(fullPath.includes("?") ? "&" : "?").concat(createQueryParams({
                                title: title,
                            })) });
                    });
                }
                if (page === 1) {
                    setArticles(data);
                    setArticlesNumber(total);
                }
                else {
                    setArticles(__spreadArray(__spreadArray([], articles, true), data, true));
                }
                setIsLoading(false);
                setHasMoreResults(data.length === size);
            });
        }
    }, [articles, hasMoreResults, pageId, parentPageId, queryParams.tag, site.id, title]);
    useEffect(function () {
        loadResults(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.tag]);
    var itemsText = React.useMemo(function () {
        if (!articlesNumber || articlesNumber === 0) {
            return "";
        }
        if (articlesNumber === 1) {
            return "1 article";
        }
        return "".concat(articlesNumber, " articles");
    }, [articlesNumber]);
    var filterTags = function (tag) {
        var tagQueryParam = queryParams.tag, otherQueryParams = __rest(queryParams, ["tag"]);
        if (tag === tagQueryParam) {
            history.push("".concat(pathname).concat(Object.keys(otherQueryParams).length > 0 ? "?".concat(createQueryParams(otherQueryParams)) : ""));
        }
        else {
            history.push("".concat(pathname, "?").concat(createQueryParams(__assign(__assign({}, otherQueryParams), { tag: tag }))));
        }
    };
    return (_jsx(Box, { px: { xs: 1, md: 3, lg: 5 }, py: 5, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, lg: 8, order: { xs: 2, lg: 1 }, children: _jsx(Box, { sx: {
                            height: 825,
                            overflow: "auto",
                            textAlign: "center",
                        }, children: _jsx(InfiniteScroll, { loadMore: loadResults, hasMore: hasMoreResults, useWindow: false, loader: _jsx(Box, { mt: 6, children: _jsx(Loader, {}, 0) }), pageStart: 1, initialLoad: false, children: _jsxs(Grid, { container: true, spacing: 1.5, sx: { pr: { xs: 0.5, lg: 2.5 }, pl: 0.5, pb: 1 }, children: [articles === null || articles === void 0 ? void 0 : articles.map(function (articleToDisplay) { return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 4, children: _jsx(ItemCardComponent, { showItemTitle: "Lire cet article", item: articleToDisplay, imagesAreInGallery: true }) }, articleToDisplay === null || articleToDisplay === void 0 ? void 0 : articleToDisplay.id)); }), articles.length === 0 && !isLoading && (_jsx(Grid, { item: true, xs: 12, textAlign: "center", children: _jsx(Typography, { children: "Aucun article" }) }))] }) }) }) }), _jsx(Grid, { item: true, xs: 12, lg: 4, order: { xs: 1, lg: 2 }, children: _jsx(ItemsCard, { itemsTitle: title || "Actualités Arpavie", recentTitle: "Articles r\u00E9cents", itemsText: itemsText, recentItems: articles.slice(0, 4), selectedTags: [queryParams.tag], tags: tags, onClickTag: filterTags, isTitle: true }) })] }) }));
};
export default ArticlesTemplate;
