import contentsTypes from "cms/enums/contentsTypes";
import customTypes from "customComponents/customTypes";
import EstablishmentTemplate from "templates/establishment/EstablishmentTemplate";
import { Templates } from "types/templates.enum";
export default {
    key: Templates.ESTABLISHMENT,
    label: "Établissement",
    templateImage: "/file-alt-solid.svg",
    Component: EstablishmentTemplate,
    initialContents: [
        {
            key: "establishmentId",
            value: "",
        },
        {
            key: "imagesGallery",
            type: contentsTypes.IMAGES_GALLERY,
            value: "",
            children: [
                {
                    key: "images",
                    type: contentsTypes.IMAGES,
                    value: "",
                    children: [
                        {
                            key: "image",
                            type: contentsTypes.IMAGE_AS_FILE,
                            value: "",
                        },
                    ],
                },
                {
                    key: "height",
                    type: contentsTypes.INPUT_NUMBER,
                    value: "500",
                },
                {
                    key: "showBullets",
                    type: contentsTypes.CHECKBOX,
                    value: "false",
                },
                {
                    key: "showFullscreenButton",
                    type: contentsTypes.CHECKBOX,
                    value: "true",
                },
            ],
        },
        {
            key: "dynamicPart",
            type: contentsTypes.DYNAMIC,
            value: "",
        },
        {
            key: "servicesTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Services",
        },
        {
            key: "residenceTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "La résidence",
        },
        {
            key: "residenceDescriptionTitle",
            type: contentsTypes.TEXT,
            value: "Présentation de la résidence",
        },
        {
            key: "residenceDescription",
            type: contentsTypes.TEXT,
            value: "",
        },
        {
            key: "residenceDescription2",
            type: contentsTypes.TEXT,
            value: "",
        },
        {
            key: "residenceAccessTitle",
            type: contentsTypes.TEXT,
            value: "Accès à la résidence",
        },
        {
            key: "pathOptionsBlock",
            type: customTypes.PATH_OPTIONS,
            value: "",
            children: [],
        },
        {
            key: "geolocTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Nous situer",
        },
    ],
};
