import React, { useContext } from "react";
import PropTypes from "prop-types";
import SubPageContext from "cms/editableComponents/subPage/SubPageContext";

const SubPage = (props) => {
  const { page, pageVersion, index, ...others } = props;
  const { subTemplate, getSubTemplate = () => null } = useContext(SubPageContext);

  // Template will take value of subTemplate or call getSubTemplate function if subTemplate is null
  let Template = subTemplate;
  if (!subTemplate) {
    Template = getSubTemplate(page.template, index);
  }

  Template = Template || (() => null);

  return <Template page={page} pageVersion={pageVersion} key={page.id} index={index} {...others} />;
};

SubPage.propTypes = {
  page: PropTypes.shape(),
  index: PropTypes.number.isRequired,
};

SubPage.defaultProps = {
  page: null,
};

export default SubPage;
