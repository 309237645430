import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import Label from "components/Label";
import InfosBlock from "routes/Search/InfosBlock";
var SearchStep = function (props) {
    var title = props.title, subtitle = props.subtitle, button = props.button, accordions = props.accordions, handleValidateStep = props.handleValidateStep, exitLink = props.exitLink, children = props.children;
    return (_jsx(Grid, { item: true, xs: 12, lg: 8, children: _jsx(Paper, { sx: { p: "26px", height: "100%" }, children: _jsxs(Grid, { container: true, direction: "column", wrap: "nowrap", sx: { height: "100%" }, children: [_jsxs(Grid, { item: true, sx: { flex: 1 }, children: [_jsx(Typography, { variant: "h2", children: title }), _jsx(Typography, { variant: "h3", sx: { mt: "5px", mb: "43px", color: "colors.noir" }, children: subtitle }), _jsxs(Grid, { container: true, spacing: 2, children: [children, _jsxs(Grid, { item: true, xs: 12, md: 6, container: true, direction: "column", children: [_jsx(Box, { sx: { mt: { xs: "40px", md: 0 } }, children: _jsx(Label, { children: "On vous aide" }) }), _jsx(InfosBlock, { accordions: accordions })] })] })] }), button && (_jsx(Grid, { item: true, container: true, alignItems: "center", justifyContent: "flex-end", spacing: 2, sx: { mt: "45px" }, children: _jsx(Grid, { item: true, xs: 12, md: "auto", onClick: exitLink ? function () { return null; } : handleValidateStep, children: _jsx(Button, { variant: "contained", color: "secondary", title: exitLink
                                    ? "En cliquant sur ce bouton, vous serez redirigé vers la pages des prestations de services et accompagnement à domicile d'ARPAVIE : ARPAVIE@Dom"
                                    : undefined, sx: { width: { xs: "100%", md: "inherit" } }, children: exitLink ? (_jsx(Link, { href: exitLink, underline: "none", sx: { color: "inherit" }, children: button })) : (button) }) }) }))] }) }) }));
};
export default SearchStep;
