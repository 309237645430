var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { EstablishmentPriceType } from "types/establishment/establishment-price-type.enum";
export var summaryTitle = "Critères supplémentaires";
var SearchEstablishmentPriceType = function (props) {
    var form = props.form, setForm = props.setForm;
    var establishmentPrices = form.establishmentPrices;
    var handleEstablishmentPrices = function (e) {
        var value = e.target.value;
        var newValue = establishmentPrices.includes(value)
            ? establishmentPrices.filter(function (type) { return type !== value; })
            : __spreadArray(__spreadArray([], establishmentPrices, true), [value], false);
        setForm(__assign(__assign({}, form), { establishmentPrices: newValue }));
    };
    return (_jsx(Grid, { container: true, direction: "column", children: Object.entries(EstablishmentPriceType).map(function (_a) {
            var establishmentPriceKey = _a[0], establishmentPriceLabel = _a[1];
            return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { value: establishmentPriceKey, 
                    // @ts-ignore
                    checked: establishmentPrices.includes(establishmentPriceKey), onChange: handleEstablishmentPrices }), label: _jsx(Typography, { variant: "donnee", children: establishmentPriceLabel }) }, establishmentPriceKey));
        }) }));
};
export default SearchEstablishmentPriceType;
