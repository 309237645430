import React, { useContext, forwardRef } from "react";
import PropTypes from "prop-types";
import { HashLink } from "react-router-hash-link";
import MuiLink from "@mui/material/Link";
import PageVersionContext from "cms/components/PageVersionContext";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { useLocation } from "react-router-dom";

const componentClassName = "Da-Link";

const RouterLink = forwardRef((props, ref) => <HashLink innerRef={ref} {...props} />);

const Link = forwardRef((props, ref) => {
  const { url, page, external, title, children, onClick, ...others } = props;
  const { setCurrentPageVersion } = useContext(PageVersionContext);
  const { pathname, search } = useLocation();

  if (!url && !page) {
    return (
      <a onClick={(e) => e.preventDefault()} className={componentClassName} {...others}>
        {children}
      </a>
    );
  }

  const handleClickLink = (e) => {
    if (typeof onClick === "function") {
      onClick(e);
    }
    if (page && !external) {
      setCurrentPageVersion(page);
    }
  };

  const dynamicProps = {
    component: RouterLink,
    target: external ? "_blank" : undefined,
    rel: external ? "noopener noreferrer" : undefined,
  };

  const path = page ? page.fullPath || "/" : sanitizeUrl(url);
  if (external || !path.startsWith("/")) {
    dynamicProps.href = path;
    dynamicProps.component = "a";
  } else {
    const [nextPathname, ...params] = path.split(/(\?|#)/i);
    let nextSearch = "";
    let nextHash = "";
    if (params && params.length > 1) {
      if (params.indexOf("?") !== -1) {
        nextSearch = `?${params[params.indexOf("?") + 1]}`;
      }
      if (params.indexOf("#") !== -1) {
        nextHash = `#${params[params.indexOf("#") + 1]}`;
      }
    }
    dynamicProps.to = {
      pathname: nextPathname,
      search: nextSearch,
      hash: nextHash,
      state: { prevPath: pathname + search },
    };
  }

  const titleLink = title || (page ? page.title : null);

  // W3C: remove attribute "to" in span component
  if (typeof document !== "undefined") {
    const seeMoreLink = document.getElementById("spanLink");
    if (seeMoreLink) {
      seeMoreLink.removeAttribute("to");
    }
  }

  return (
    <MuiLink
      ref={ref}
      underline="none"
      onClick={handleClickLink}
      color="textPrimary"
      {...dynamicProps}
      className={componentClassName}
      title={titleLink}
      {...others}
    >
      {children}
    </MuiLink>
  );
});

Link.propTypes = {
  url: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  title: PropTypes.string,
  onClick: PropTypes.func,
  external: PropTypes.bool,
  children: PropTypes.node,
};

Link.defaultProps = {
  url: null,
  page: null,
  title: null,
  onClick: () => null,
  external: false,
  children: null,
};

export default Link;
