var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useCallback, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import Box from "@mui/system/Box";
var InfoBlock = function (_a) {
    var _b, _c;
    var _d = _a.id, id = _d === void 0 ? "" : _d, _e = _a.firstTitle, firstTitle = _e === void 0 ? null : _e, _f = _a.firstSubTitle, firstSubTitle = _f === void 0 ? null : _f, _g = _a.firstDynamicPart, firstDynamicPart = _g === void 0 ? null : _g, _h = _a.firstPartTitleColor, firstPartTitleColor = _h === void 0 ? "colors.noir" : _h, _j = _a.firstPartColor, firstPartColor = _j === void 0 ? "rgba(0, 0, 0, 0.8)" : _j, _k = _a.firstPartBackgroundColor, firstPartBackgroundColor = _k === void 0 ? "colors.gris_5" : _k, _l = _a.secondTitle, secondTitle = _l === void 0 ? null : _l, _m = _a.secondSubTitle, secondSubTitle = _m === void 0 ? null : _m, _o = _a.secondDynamicPart, secondDynamicPart = _o === void 0 ? null : _o, _p = _a.secondPartColor, secondPartColor = _p === void 0 ? "#FFFFFF" : _p, _q = _a.secondPartBackgroundColor, secondPartBackgroundColor = _q === void 0 ? "colors.bleu" : _q, _r = _a.customized, customized = _r === void 0 ? false : _r, others = __rest(_a, ["id", "firstTitle", "firstSubTitle", "firstDynamicPart", "firstPartTitleColor", "firstPartColor", "firstPartBackgroundColor", "secondTitle", "secondSubTitle", "secondDynamicPart", "secondPartColor", "secondPartBackgroundColor", "customized"]);
    var angle = Math.PI / 10;
    var spacing = 32;
    var containerRef = useRef(null);
    var _s = useState(null), refWidth = _s[0], setRefWidth = _s[1];
    var _t = useState(null), refHeight = _t[0], setRefHeight = _t[1];
    var handleResize = useCallback(function () {
        var _a, _b;
        setRefWidth(((_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || null);
        setRefHeight(((_b = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_b = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth, (_c = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _c === void 0 ? void 0 : _c.offsetHeight]);
    useEffect(function () {
        window.addEventListener("resize", handleResize);
        handleResize();
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);
    var getShift = function (width) {
        return (width * Math.sin(angle)) / (Math.sin(Math.PI / 2 - angle) * 2);
    };
    var getShiftPercent = function (width) {
        return refWidth ? (100 * (getShift(width) - spacing / 2)) / refWidth : 0;
    };
    return (_jsx("div", { onLoad: handleResize, children: _jsxs(Grid, __assign({ container: true, sx: { overflow: "hidden" }, ref: containerRef }, others, { children: [_jsx(Grid, { item: true, sx: {
                        overflow: { lg: "hidden" },
                        mr: { lg: -(getShift(refHeight) - spacing / 2) / 8 },
                        width: { xs: "100%", lg: "".concat(50 + getShiftPercent(refHeight), "%") },
                    }, children: _jsx(Box, { sx: {
                            bgcolor: firstPartBackgroundColor,
                            transform: { lg: "skew(-".concat(angle, "rad)") },
                            width: { lg: "100%" },
                            height: { lg: "100%" },
                            ml: { lg: -getShift(refHeight) / 8 },
                        }, children: _jsxs(Box, { sx: {
                                transform: { lg: "skew(".concat(angle, "rad)") },
                                ml: { lg: getShift(refHeight) / 8 },
                                height: { lg: "100%" },
                                pl: { xs: 4, md: 11, lg: 16.75 },
                                pt: { xs: 4, md: 11, lg: 5.75 },
                                pr: { xs: 4, md: 11, lg: 14 },
                                pb: { xs: 4, md: 11, lg: 5.75 },
                                color: firstPartColor,
                                "& h3, & h4, & h5, & h6": customized
                                    ? {}
                                    : {
                                        color: "".concat(firstPartColor, " !important"),
                                    },
                                "& h2": {
                                    color: firstPartTitleColor,
                                },
                            }, children: [_jsx(Typography, { variant: "h2", sx: { mb: 2, mt: { lg: 5.25 } }, children: firstTitle }), _jsx(Typography, { component: "h3", variant: "subtitle1", sx: { color: firstPartColor }, children: firstSubTitle }), firstDynamicPart] }) }) }), _jsx(Grid, { item: true, sx: {
                        ml: { lg: -(getShift(refHeight) - spacing / 2 + 1) / 8 },
                        overflow: { lg: "hidden" },
                        width: { xs: "100%", lg: "".concat(50 + getShiftPercent(refHeight), "%") },
                    }, children: _jsx(Box, { sx: {
                            bgcolor: secondPartBackgroundColor,
                            transform: { lg: "skew(-".concat(angle, "rad)") },
                            width: { lg: "100%" },
                            height: { lg: "100%" },
                            ml: { lg: getShift(refHeight) / 8 },
                        }, children: _jsxs(Box, { sx: {
                                transform: { lg: "skew(".concat(angle, "rad)") },
                                ml: { lg: getShift(refHeight) / 8 },
                                height: { lg: "100%" },
                                pl: { xs: 4, md: 11, lg: 3 },
                                pt: { xs: 4, md: 11, lg: 5.75 },
                                pr: { xs: 4, md: 11, lg: 27.5 },
                                pb: { xs: 4, md: 11, lg: 5.75 },
                                color: secondPartColor,
                                "& h2, & h3, & h4, & h5, & h6": customized
                                    ? {}
                                    : {
                                        color: "".concat(secondPartColor, " !important"),
                                    },
                            }, children: [_jsx(Typography, { variant: "h2", sx: { mb: 2, mt: { lg: 5.25 } }, children: secondTitle }), _jsx(Typography, { component: "h3", variant: "subtitle1", sx: { color: secondPartColor }, children: secondSubTitle }), secondDynamicPart] }) }) })] }), id) }));
};
export default InfoBlock;
