var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { Button, Typography, Grid, Paper, Box } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { Templates } from "types/templates.enum";
import SiteContext from "cms/components/SiteContext";
import { useQuery } from "react-query";
var CustomCard = function (_a) {
    var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.type, type = _c === void 0 ? "medicalSupport" : _c, _d = _a.title, title = _d === void 0 ? "" : _d, others = __rest(_a, ["id", "type", "title"]);
    var site = useContext(SiteContext);
    var cardDescriptions = {
        medicalSupport: (_jsxs("p", { children: ["Vous avez besoin ", _jsx("strong", { children: "d\u2019une assistance m\u00E9dicalis\u00E9e 24/24" }), ". Il vous faut donc un h\u00E9bergement dans une de nos r\u00E9sidences de type maison de retraite m\u00E9dicalis\u00E9e, EHPAD."] })),
        needAssist: (_jsxs("p", { children: ["Vous avez besoin ", _jsx("strong", { children: "d\u2019une assistance non m\u00E9dicalis\u00E9e 24/24" }), ". Il vous faut donc un h\u00E9bergement dans une de nos r\u00E9sidences de type maison de retraite autonomie."] })),
        stayHome: (_jsxs("p", { children: ["Vous avez besoin ", _jsx("strong", { children: "d\u2019un accompagnement chez vous" }), ". Il vous faut donc un service d\u2019accompagnement pour vous permettre de rester \u00E0 votre domicile."] })),
    };
    var _e = useState("/"), arpavieDomPathName = _e[0], setArpavieDomPathName = _e[1];
    var _f = useQuery(["arpavie_dom_page", site === null || site === void 0 ? void 0 : site.id], function () {
        return axios.get("/findPagesByTemplates", {
            params: {
                templates: Templates.ARPAVIE_DOM,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                sortOrder: "DESC",
            },
        });
    }), dataArpavieDomPage = _f.data, loading = _f.isFetching;
    React.useEffect(function () {
        var _a, _b, _c;
        if (!loading) {
            var newPathname = (_c = (_b = (_a = dataArpavieDomPage === null || dataArpavieDomPage === void 0 ? void 0 : dataArpavieDomPage.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.find(function (elt) { return elt.fullPath; })) === null || _c === void 0 ? void 0 : _c.fullPath;
            if (newPathname) {
                setArpavieDomPathName(newPathname);
            }
        }
    }, [dataArpavieDomPage, loading]);
    return (_jsx(Paper, __assign({ square: true, sx: {
            height: "100%",
            position: "relative",
            borderRadius: 1,
        } }, others, { children: _jsxs(Grid, { container: true, direction: "column", justifyContent: "space-between", sx: {
                height: "100%",
                transition: "background-color ease-in 250ms",
                borderRadius: 1,
                px: { xs: 3, lg: 4, xl: 6.75 },
                pt: 3.5,
                pb: 2.5,
                textAlign: "center",
                ".custom-card-description, .custom-card-button": {
                    display: { lg: "none" },
                },
                ".custom-card-icon": {
                    display: { xs: "none", lg: "flex" },
                },
                bgcolor: "colors.blanc",
                "&:hover": {
                    position: { lg: "absolute" },
                    height: "auto",
                    top: 0,
                    left: 0,
                    right: 0,
                    bgcolor: { lg: "colors.recherche_clair_2" },
                    ".custom-card-description, .custom-card-button": {
                        display: "block",
                    },
                    ".custom-card-icon": {
                        display: "none",
                    },
                },
            }, children: [_jsxs(Grid, { item: true, children: [_jsx(Typography, { variant: "h3", sx: {
                                color: "colors.recherche_fonce",
                                mb: 2,
                                fontSize: { xs: "1.428rem", lg: "1.928rem" },
                            }, children: title }), _jsx(Box, { sx: {
                                fontSize: { lg: "1.429rem" },
                                lineHeight: "1.741rem",
                                mb: 2,
                            }, className: "custom-card-description", children: cardDescriptions[type] })] }), _jsx(Grid, { item: true, sx: {
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "1.429rem",
                    }, className: "custom-card-icon", children: _jsx(Icon, { icon: "eye", background: "colors.recherche", color: "colors.blanc" }) }), _jsx(Grid, { item: true, className: "custom-card-button", children: _jsx(Button, { variant: "cta", to: type === "stayHome" ? arpavieDomPathName : "/recherche-detaillee?".concat(type, "=1"), component: HashLink, endIcon: _jsx(Icon, { icon: "search", background: "colors.blanc", color: "colors.noir" }), children: type === "stayHome" ? "Quels sont vos besoins ?" : "Trouver ma résidence" }) })] }) }), id));
};
export default CustomCard;
