const severitiesTypes = {
  ERROR: {
    key: "ERROR",
    label: "Alerte",
    backgroundColor: "#fbccc6",
    icon: "exclamation-triangle",
    iconColor: "#c70000",
  },
  WARNING: {
    key: "WARNING",
    label: "Attention",
    backgroundColor: "rgb(255, 233, 203)",
    icon: "exclamation-circle",
    iconColor: "#ff9800",
  },
  INFO: {
    key: "INFO",
    label: "Info",
    backgroundColor: "rgb(225, 242, 255)",
    icon: "info-circle",
    iconColor: "#2196f3",
  },
  SUCCESS: {
    key: "SUCCESS",
    label: "Succès",
    backgroundColor: "rgb(195, 255, 195)",
    icon: "check-circle",
    iconColor: "#4caf50",
  },
};

export default severitiesTypes;
