var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Paper, Typography } from "@mui/material";
import Image, { ImageContainer } from "cms/editableComponents/Image";
import Link from "cms/editableComponents/Link";
import Text from "cms/editableComponents/Text";
import { getDateFromTimestamp } from "cms/utils/commonUtils";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import ItemTags from "components/ItemTags";
import MultilineEllipsis from "components/MultilineEllipsis";
var ItemCard = function (_a) {
    var _b, _c, _d, _e, _f;
    var showItemTitle = _a.showItemTitle, _g = _a.imagesAreInGallery, imagesAreInGallery = _g === void 0 ? false : _g, item = _a.item;
    var history = useHistory();
    var publishedDate = item.publishedDate, title = item.title, shortDescription = item.shortDescription, contents = item.contents, _h = item.tags, tags = _h === void 0 ? [] : _h, image = item.image;
    var imgContainerProps = {};
    if (imagesAreInGallery) {
        var imagesGallery = contents.find(function (child) { return child.key === "imagesGallery"; });
        var images = (_c = (_b = imagesGallery === null || imagesGallery === void 0 ? void 0 : imagesGallery.children) === null || _b === void 0 ? void 0 : _b.find(function (child) { return child.key === "images"; })) === null || _c === void 0 ? void 0 : _c.children;
        var imageInGalleryString = images && ((_f = (_e = (_d = images[0]) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e.find(function (child) { return child.key === "file"; })) === null || _f === void 0 ? void 0 : _f.value);
        var imageInGallery = imageInGalleryString && JSON.parse(imageInGalleryString);
        imgContainerProps.url = (imageInGallery === null || imageInGallery === void 0 ? void 0 : imageInGallery.url) || "/default-image-item.jpg";
        imgContainerProps.alt = (imageInGallery === null || imageInGallery === void 0 ? void 0 : imageInGallery.alt) || "";
        imgContainerProps.title = (imageInGallery === null || imageInGallery === void 0 ? void 0 : imageInGallery.title) || "";
    }
    else {
        imgContainerProps.url = (image === null || image === void 0 ? void 0 : image.url) || "/default-image-item.jpg";
        imgContainerProps.alt = (image === null || image === void 0 ? void 0 : image.alt) || "";
        imgContainerProps.title = (image === null || image === void 0 ? void 0 : image.title) || "";
    }
    var isValidDate = dayjs(publishedDate).isValid();
    var dateString = isValidDate ? getDateFromTimestamp(publishedDate, "DD/MM/YYYY") : null;
    var onKeyPressItemDetail = function (e) {
        if (e.key === "Enter") {
            e.preventDefault();
            history.push(item.fullPath);
        }
    };
    var buttonProps = {
        page: item,
        component: Link,
    };
    var tagsToDisplay = tags || [];
    if (tagsToDisplay.length > 3) {
        tagsToDisplay = tagsToDisplay.slice(0, 2);
    }
    return (_jsxs(Paper, { sx: { borderRadius: 1, position: "relative" }, tabIndex: 0, role: "button", onKeyDown: onKeyPressItemDetail, children: [_jsxs(Box, { sx: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    display: { xs: "none", lg: "flex" },
                    flexDirection: "column",
                    textAlign: "center",
                    bgcolor: "colors.bleu",
                    color: "#fff",
                    zIndex: 20,
                    opacity: 0,
                    transition: "opacity ease-in 250ms",
                    "&:hover": {
                        opacity: 1,
                    },
                }, children: [_jsxs(Box, { sx: { pt: 4.75, px: 2.5, height: "345px", display: "flex", flexDirection: "column" }, children: [_jsx(Typography, { color: "inherit", variant: "h4", sx: { mb: 2 }, title: title, children: _jsx(MultilineEllipsis, { children: title }) }), isValidDate && (_jsx(Typography, { color: "inherit", variant: "legend", children: dateString })), _jsx(Box, { sx: {
                                    my: 2,
                                    display: "block",
                                    whiteSpace: "pre-wrap",
                                    overflow: "auto",
                                    "&::-webkit-scrollbar": {
                                        width: "4px",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        borderRadius: 10,
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        bgcolor: "colors.gris_5",
                                        borderRadius: "10px",
                                    },
                                }, children: _jsx(Text
                                //  @ts-ignore
                                , { 
                                    //  @ts-ignore
                                    sx: {
                                        "& p": {
                                            my: 0,
                                            overflow: "hidden",
                                        },
                                    }, children: shortDescription }) })] }), _jsx(Box, { children: _jsx(Button, __assign({}, buttonProps, { variant: "outlined", sx: {
                                "&:hover, &:focus": {
                                    color: "colors.bleu",
                                    bgcolor: "colors.blanc",
                                    borderColor: "colors.bleu",
                                },
                            }, children: showItemTitle })) })] }), _jsx(Box, { sx: {
                    position: "absolute",
                    top: 16,
                    right: 16,
                    zIndex: 10,
                }, children: _jsx(ItemTags, { tags: tags || [], limit: 3, direction: "vertical" }) }), _jsx(ImageContainer, { ratio: "", children: _jsx(Image, { file: { url: (imgContainerProps === null || imgContainerProps === void 0 ? void 0 : imgContainerProps.url) || "/default-image-item.jpg" }, alt: (imgContainerProps === null || imgContainerProps === void 0 ? void 0 : imgContainerProps.alt) || "", title: (imgContainerProps === null || imgContainerProps === void 0 ? void 0 : imgContainerProps.title) || "", sx: { height: "200px" }, disableLazy: true }) }), _jsxs(Box, { px: 2, pt: 2, pb: 3.5, sx: { minHeight: 195, position: "relative", textAlign: "center" }, children: [_jsx(Typography, { variant: "h4", sx: { mb: 1.25 }, title: title, children: _jsx(MultilineEllipsis, { children: title }) }), isValidDate && (_jsx(Typography, { variant: "legend", sx: { color: "colors.bleu_fonce" }, children: dateString })), _jsx(Button, __assign({}, buttonProps, { variant: "outlined", sx: {
                            position: "absolute",
                            bottom: 8,
                            right: 8,
                            display: { xs: "block", lg: "none" },
                        }, children: showItemTitle }))] })] }));
};
export default ItemCard;
