var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
import ServiceItem from "components/FeatureItem";
import CustomPopper from "components/CustomPopper";
import { getFeatureCategoryLabel } from "utils/featureUtils";
import { sortAlphabetically } from "cms/utils/commonUtils";
var FeaturesBlock = function (props) {
    var category = props.category, includedFeatures = props.includedFeatures, complementaryFeatures = props.complementaryFeatures, _a = props.includedItemProps, includedItemProps = _a === void 0 ? {} : _a, _b = props.complementaryItemProps, complementaryItemProps = _b === void 0 ? {} : _b, _c = props.displayLegend, displayLegend = _c === void 0 ? true : _c;
    var _d = React.useState(null), anchorEl = _d[0], setAnchorEl = _d[1];
    var _e = React.useState(false), openLegend = _e[0], setOpenLegend = _e[1];
    var responsiveItemsProps = {
        xs: 12,
        sm: 4,
        md: 6,
        lg: 4,
    };
    var sortedIncludedFeatures = includedFeatures === null || includedFeatures === void 0 ? void 0 : includedFeatures.sort(function (a, b) { return sortAlphabetically(a, b); });
    var sortedComplementaryFeatures = complementaryFeatures === null || complementaryFeatures === void 0 ? void 0 : complementaryFeatures.sort(function (a, b) { return sortAlphabetically(a, b); });
    var handleClickLegend = function (event) {
        setAnchorEl(event.currentTarget);
        setOpenLegend(true);
    };
    var handleCloseLegend = React.useCallback(function () {
        setOpenLegend(false);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex", alignItems: "center", mb: 3.375 }, children: [_jsx(Typography, { variant: "h3", sx: { mr: displayLegend ? 2 : 0 }, children: getFeatureCategoryLabel(category) }), displayLegend && (_jsx(Box, { sx: { color: "colors.bleu", cursor: "pointer" }, onClick: handleClickLegend, children: _jsx(Icon, { icon: "info-circle", type: "fas" }) }))] }), displayLegend && (_jsxs(CustomPopper, { onClose: handleCloseLegend, open: openLegend, anchorEl: anchorEl, placement: "right-start", disableClickAway: true, sx: { width: 250 }, children: [_jsx(Typography, { variant: "subtitle1", sx: { mb: 2, color: "colors.bleu" }, children: "L\u00E9gende des services" }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", mb: 2 }, children: [_jsx(Box, { sx: { mr: 1 }, children: _jsx(ServiceItem, __assign({ feature: {
                                        name: "",
                                        description: "",
                                        icon: "utensils",
                                    }, alignItems: "left", bgcolor: "#E9F7F7" }, includedItemProps)) }), _jsx(Box, { children: "Service inclus" })] }), _jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [_jsx(Box, { sx: { mr: 1 }, children: _jsx(ServiceItem, __assign({ feature: {
                                        name: "",
                                        description: "",
                                        icon: "utensils",
                                    }, alignItems: "left" }, complementaryItemProps)) }), _jsx(Box, { children: "Service compl\u00E9mentaire" })] })] })), __spreadArray(__spreadArray([], sortedIncludedFeatures, true), sortedComplementaryFeatures, true).length > 0 && (_jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-start", children: [sortedIncludedFeatures.map(function (feature) { return (_createElement(Grid, __assign({ item: true }, responsiveItemsProps, { key: feature.id, sx: { minHeight: 135 } }),
                        _jsx(ServiceItem, __assign({ feature: feature, bgcolor: "#E9F7F7" }, includedItemProps)))); }), sortedComplementaryFeatures.map(function (feature) { return (_createElement(Grid, __assign({ item: true }, responsiveItemsProps, { key: feature.id, sx: { minHeight: 135 } }),
                        _jsx(ServiceItem, __assign({ feature: feature }, complementaryItemProps)))); })] }))] }));
};
export default FeaturesBlock;
