import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import Modal from "components/Modal";
import CookiesContext from "components/CookiesContext";
import CookiesTable from "components/CookiesTable";
import useEnvironment from "cms/back-office/hooks/useEnvironment";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { isCookieExist } from "cms/utils/cookieUtils";
var CookiesModal = function (_a) {
    var handleCookiesModal = _a.handleCookiesModal;
    var _b = useEnvironment(), matomo_track_url = _b.matomo_track_url, matomo_site_id = _b.matomo_site_id;
    var pushInstruction = useMatomo().pushInstruction;
    var openCookiesModal = useContext(CookiesContext).openCookiesModal;
    var _c = useState(false), isMatomoUserOptOut = _c[0], setIsMatomoUserOptOut = _c[1];
    useEffect(function () {
        setIsMatomoUserOptOut(isCookieExist("mtm_consent_removed"));
    }, []);
    var handleSwitchMatomoUserOptOut = function () {
        if (isMatomoUserOptOut) {
            pushInstruction("forgetUserOptOut");
        }
        else {
            pushInstruction("optUserOut");
        }
        setIsMatomoUserOptOut(!isMatomoUserOptOut);
    };
    var requiredCookiesRows = [
        {
            id: 1,
            name: "Local Storage",
            finality: "Données stockées sur votre navigateur afin d'enregistrer vos choix concernant l'utilisation des cookies. Ces données restent sur votre navigateur et ne sont jamais transmises.",
            conservation: "Valide jusqu'à la suppression des données en cache",
        },
        {
            id: 2,
            name: "antibot, invisiblecaptcha",
            finality: "Ces cookies sont utilisés pour stocker l'id anti-robot et l'id du captcha invisible.",
            conservation: "12 mois",
        },
    ];
    var matomoEnabled = matomo_track_url && matomo_site_id;
    if (matomoEnabled) {
        requiredCookiesRows.push({
            id: 3,
            name: "mtm_consent_removed",
            finality: "Ce cookie est utilisé pour vérifier si la mesure d'aucience a été refusée par l'utilisateur",
            conservation: "30 jours",
        });
        requiredCookiesRows.push({
            id: 4,
            name: "MATOMO_SESSID",
            finality: "Ce cookie est utilisé pour stocker l'id de session de l'outil de mesure d'audience",
            conservation: "14 jours",
        });
    }
    return (_jsx(Modal, { title: _jsx(Typography, { children: "\u00C0 propos des cookies" }), content: _jsxs(_Fragment, { children: [_jsx(Link, { href: "/donnees-personnelles-et-cookies", target: "_blank", children: "En savoir plus" }), _jsx(Box, { sx: { mb: 3.5 }, children: _jsx(CookiesTable, { cookiesRows: requiredCookiesRows, checked: true, disabled: true, subtitle: "Cookies strictement n\u00E9cessaires au fonctionnement du site" }) }), matomoEnabled && (_jsx(Box, { sx: { mb: 3.5 }, children: _jsx(CookiesTable, { checked: !isMatomoUserOptOut, onChange: handleSwitchMatomoUserOptOut, description: "L'outil de mesure d'audience MATOMO est respecteux du RGPD et exempt\u00E9 de consentement. Il permet d'avoir les donn\u00E9es relatives \u00E0 la mesure d'audience du site et produire des donn\u00E9es statistiques anonymes. Si toutefois vous souhaitez absolument vous opposer au suivi de votre navigation, vous pouvez le signifier en d\u00E9sactivant cette fonctionnalit\u00E9", subtitle: "Mesure d'audience", switchLabel: isMatomoUserOptOut ? "Refusée" : "Acceptée" }) }))] }), open: openCookiesModal, closeModal: handleCookiesModal }));
};
export default CookiesModal;
