import React from "react";
import PropTypes from "prop-types";
import Link from "cms/editableComponents/Link";
import Box from "@mui/material/Box";

const ImageBlock = (props) => {
  const { width, mobileWidth, justifyContent, link, image } = props;

  return (
    <Box display="flex" justifyContent={justifyContent}>
      <Box sx={{ height: "100%", width: { xs: mobileWidth || "100%", lg: width || "100%" } }}>
        {link && (link.page || link.url) ? <Link {...link}>{image}</Link> : image}
      </Box>
    </Box>
  );
};

ImageBlock.propTypes = {
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  justifyContent: PropTypes.oneOf(["flex-start", "center", "flex-end"]).isRequired,
  link: PropTypes.shape(),
  image: PropTypes.shape().isRequired,
};

ImageBlock.defaultProps = {
  width: null,
  mobileWidth: null,
  link: null,
};

export default ImageBlock;
