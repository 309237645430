var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Button, FormHelperText, LinearProgress, Link, Tooltip, Typography } from "@mui/material";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
import CaptchaService from "services/CaptchaService";
import { CheckCaptchaStatus } from "types/captcha/check-captcha-status.enum";
import { AntibotResult } from "types/captcha/antibot-result.enum";
import Modal from "cms/back-office/components/Modal";
var Mailto = function (props) {
    var text = props.text, _a = props.recipient, recipient = _a === void 0 ? "" : _a, _b = props.subject, subject = _b === void 0 ? "" : _b, _c = props.body, body = _c === void 0 ? "" : _c, _d = props.isLink, isLink = _d === void 0 ? false : _d;
    var _e = useState(false), openModal = _e[0], setOpenModal = _e[1];
    var _f = useState(false), loaderCaptcha = _f[0], setLoaderCaptcha = _f[1];
    var _g = useState(CheckCaptchaStatus.init), checkCaptchaStatus = _g[0], setCheckCaptchaStatus = _g[1];
    var _h = useState(false), captchaAntibotInfo = _h[0], setCaptchaAntibotInfo = _h[1];
    var _j = useState(AntibotResult.unknown), antibotResult = _j[0], setAntibotResult = _j[1];
    var getCaptchaAntibotInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var captchaKey, captchaURL, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoaderCaptcha(true);
                    captchaKey = "";
                    captchaURL = "";
                    getEnvironmentPromise().then(function (_a) {
                        var liveidentity_key = _a.liveidentity_key, liveidentity_URL = _a.liveidentity_URL;
                        if (liveidentity_key && liveidentity_URL) {
                            captchaKey = liveidentity_key;
                            captchaURL = liveidentity_URL;
                        }
                    });
                    return [4 /*yield*/, CaptchaService.getAntibotInfo()];
                case 1:
                    response = _a.sent();
                    // @ts-ignore
                    LI_ANTIBOT.loadAntibot([
                        "PICTO",
                        "AUDIO",
                        "FR",
                        captchaKey,
                        captchaURL,
                        "",
                        "",
                        response.antibotId,
                        response.requestId,
                        true,
                    ]);
                    setCaptchaAntibotInfo(true);
                    setLoaderCaptcha(false);
                    setAntibotResult(response.antibotResult);
                    return [2 /*return*/];
            }
        });
    }); };
    var checkToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var antibotToken, resultToken, responseAntibotToken;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!captchaAntibotInfo) return [3 /*break*/, 4];
                    antibotToken = ((_a = document.getElementById("li-antibot-token")) === null || _a === void 0 ? void 0 : _a.value) || null;
                    resultToken = "";
                    if (!antibotToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, CaptchaService.verifyAntibotToken(antibotToken)];
                case 1:
                    responseAntibotToken = _b.sent();
                    resultToken = responseAntibotToken === null || responseAntibotToken === void 0 ? void 0 : responseAntibotToken.result;
                    if (resultToken === "FAILED") {
                        setOpenModal(false);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    setCheckCaptchaStatus(CheckCaptchaStatus.to_validate);
                    _b.label = 3;
                case 3: return [2 /*return*/, resultToken];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var resetCaptcha = function () {
        setLoaderCaptcha(false);
        setOpenModal(false);
        setCaptchaAntibotInfo(false);
        setCheckCaptchaStatus(CheckCaptchaStatus.init);
    };
    var sendCandidate = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (recipient)
                window.location.href = "mailto:".concat(recipient, "?subject=").concat(subject, "&body=").concat(body);
            resetCaptcha();
            return [2 /*return*/];
        });
    }); };
    var handleValidate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isCaptchaValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkToken()];
                case 1:
                    isCaptchaValid = _a.sent();
                    if (isCaptchaValid === "SUCCESS") {
                        setLoaderCaptcha(true);
                        setCheckCaptchaStatus(CheckCaptchaStatus.validated);
                        sendCandidate();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, { sx: { textAlign: "center" }, children: [isLink ? (_jsx(Link, { onClick: function () {
                    setOpenModal(true);
                    getCaptchaAntibotInfo();
                }, sx: { cursor: "crosshair" }, component: "span", children: text })) : (_jsx(Tooltip, { title: antibotResult === AntibotResult.blacklist
                    ? "Votre connexion est consid\u00E9r\u00E9e \u00E0 risque, par cons\u00E9quent vous ne pouvez pas acc\u00E9der \u00E0 nos services. Pour d\u00E9bloquer votre acc\u00E8s, adressez un mail \u00E0 : communication@arpavie.fr"
                    : "", children: _jsx(Box, { component: "span", children: _jsxs(Button, { onClick: function () {
                            setOpenModal(true);
                            getCaptchaAntibotInfo();
                        }, variant: "contained", disabled: antibotResult === AntibotResult.blacklist, children: [text, loaderCaptcha && (_jsx(LinearProgress, { sx: {
                                    position: "absolute",
                                    bottom: "-5px",
                                    left: "13px",
                                    right: "13px",
                                    borderRadius: "0 0 20px 20px",
                                } }))] }) }) })), _jsx(Modal, { open: openModal, size: "sm", onClose: function () { return setOpenModal(false); }, children: _jsxs(Box, { sx: {
                        textAlign: "center",
                        mt: 1.5,
                        "& #li-antibot": { width: "435px", height: "280px" },
                    }, children: [_jsx(Box, { id: "li-antibot", sx: { margin: "auto" } }), checkCaptchaStatus === CheckCaptchaStatus.to_validate && (_jsx(FormHelperText, { sx: { textAlign: "center", color: "colors.rouge_clair" }, children: "Veuillez valider le captcha" })), _jsx(Typography, { variant: "body1", sx: { pt: "10px" }, children: "Vous allez \u00EAtre redirig\u00E9 vers votre bo\u00EEte mail." }), _jsx(Button, { onClick: function () { return handleValidate(); }, variant: "contained", sx: { mt: 2 }, children: "Valider" })] }) })] }));
};
export default Mailto;
