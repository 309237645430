import axios from "axios";

export default (name) =>
  class CRUD {
    static get = (id, options) => axios.get(`/api/${name}/${id}`, options).then((r) => r.data);

    static find = (options = {}) => {
      const { params = {} } = options;
      const { offset } = params;
      return axios
        .get(`/api/${name}`, {
          ...options,
          params: {
            offset: 0,
            ...params,
          },
        })
        .then((r) => {
          const { data } = r;
          if (offset !== undefined) {
            // returns pagination infos
            return data;
          }
          // returns data only
          return data.data;
        });
    };

    static create = (object) => axios.post(`/api/${name}`, object).then((r) => r.data);

    static update = (object) => axios.patch(`/api/${name}/${object.id}`, object).then((r) => r.data);

    static delete = (object) => axios.delete(`/api/${name}/${object.id}`).then((r) => r.data);
  };
