var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from "axios";
import { useQuery } from "react-query";
import { Templates } from "types/templates.enum";
export var useArticlesPageVersion = function (site, options) {
    if (options === void 0) { options = {}; }
    return useQuery(["articles_page", site === null || site === void 0 ? void 0 : site.id], function () {
        return axios
            .get("/findPagesByTemplates", {
            params: {
                templates: Templates.ARTICLES,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                sortOrder: "DESC",
            },
        })
            .then(function (r) { var _a; return (_a = r.data) === null || _a === void 0 ? void 0 : _a.data[0]; });
    }, __assign({ enabled: Boolean(site === null || site === void 0 ? void 0 : site.id) }, options));
};
export var useArticlesTagsByParent = function (parentId, site, options) {
    if (options === void 0) { options = {}; }
    return useQuery(["articles_tag_by_parent", parentId, site === null || site === void 0 ? void 0 : site.id], function () {
        return axios
            .get("/getTemplatesTags", {
            params: {
                filter: ["page.parentId||eq||".concat(parentId)],
                templates: Templates.ARTICLE,
                siteId: site.id,
            },
        })
            .then(function (r) { return r.data; });
    }, __assign({ enabled: Boolean(site === null || site === void 0 ? void 0 : site.id) }, options));
};
export var useLastArticlesByParent = function (parentId, site, filters, options) {
    if (filters === void 0) { filters = []; }
    if (options === void 0) { options = {}; }
    return useQuery(["last_articles_by_parent", parentId, site === null || site === void 0 ? void 0 : site.id, filters], function () {
        return axios
            .get("/findPagesByTemplates", {
            params: {
                filter: __spreadArray(__spreadArray([], filters, true), ["page.parentId||eq||".concat(parentId)], false),
                templates: Templates.ARTICLE,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                sortOrder: "DESC",
                per_page: 10,
            },
        })
            .then(function (r) { return r.data; });
    }, __assign({ enabled: Boolean(site === null || site === void 0 ? void 0 : site.id) }, options));
};
