import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Typography, Grid, Box } from "@mui/material";
import Image, { ImageContainer } from "cms/editableComponents/Image";
import { getDateFromTimestamp } from "cms/utils/commonUtils";
import ItemTags from "components/ItemTags";
import ItemsCard from "components/ItemsCard";
import { Templates } from "types/templates.enum";
import { createQueryParams } from "cms/utils/urlUtils";
import axios from "axios";
import SiteContext from "cms/components/SiteContext";
var JobOfferTemplate = function (_a) {
    var _b, _c, _d, _e;
    var page = _a.page;
    var id = page.id, contents = page.contents, title = page.title, publishedDate = page.publishedDate, image = page.image, _f = page.tags, tags = _f === void 0 ? [] : _f;
    var dynamicPart = contents.dynamicPart, mailto = contents.mailto;
    var site = useContext(SiteContext);
    var _g = useState([]), jobOffers = _g[0], setJobOffers = _g[1];
    var _h = useState(0), jobOffersNumber = _h[0], setJobOffersNumber = _h[1];
    var _j = useState([]), allTags = _j[0], setAllTags = _j[1];
    var _k = useState("/"), pathname = _k[0], setPathname = _k[1];
    var history = useHistory();
    useEffect(function () {
        axios
            .get("/getTemplatesTags", {
            params: {
                templates: Templates.JOB_OFFER,
                siteId: site === null || site === void 0 ? void 0 : site.id,
            },
        })
            .then(function (response) {
            setAllTags(response.data || []);
        });
    }, [site.id]);
    useEffect(function () {
        axios
            .get("/findPagesByTemplates", {
            params: {
                filter: ["id||ne||".concat(id)],
                templates: Templates.JOB_OFFER,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                sortOrder: "DESC",
                per_page: 4,
            },
        })
            .then(function (response) {
            var _a = response.data, _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.total, total = _c === void 0 ? 0 : _c;
            setJobOffers(data);
            setJobOffersNumber(total + 1);
        });
    }, [site.id, id]);
    useEffect(function () {
        axios
            .get("/findPagesByTemplates", {
            params: {
                templates: Templates.JOB_OFFERS,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                sortOrder: "DESC",
            },
        })
            .then(function (response) {
            var _a;
            var data = response.data.data;
            if (data) {
                var newPathname = (_a = data.find(function (elt) { return elt.fullPath; })) === null || _a === void 0 ? void 0 : _a.fullPath;
                if (newPathname) {
                    setPathname(newPathname);
                }
            }
        });
    }, [site.id]);
    var itemsText = React.useMemo(function () {
        if (!jobOffersNumber || jobOffersNumber === 0) {
            return "Aucune offre";
        }
        if (jobOffersNumber === 1) {
            return "1 offre";
        }
        return "".concat(jobOffersNumber, " offres");
    }, [jobOffersNumber]);
    var filterTags = function (tag) {
        history.push("".concat(pathname, "?").concat(createQueryParams({
            tag: tag,
        })));
    };
    return (_jsx("article", { children: _jsxs(Grid, { container: true, columnSpacing: 4, sx: { pt: 5, px: 4 }, children: [_jsxs(Grid, { item: true, xs: 12, md: 8, children: [_jsx(Box, { sx: {
                                position: "relative",
                            }, children: _jsx(Box, { sx: {
                                    position: "absolute",
                                    top: 16,
                                    right: 16,
                                    zIndex: 10,
                                }, children: _jsx(ItemTags, { tags: tags || [] }) }) }), _jsx(ImageContainer, { ratio: 45, children: _jsx(Image, { file: {
                                    url: ((_c = (_b = image === null || image === void 0 ? void 0 : image.props) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.url) || "/default-image-item.jpg",
                                }, alt: ((_d = image === null || image === void 0 ? void 0 : image.props) === null || _d === void 0 ? void 0 : _d.alt) || "", title: ((_e = image === null || image === void 0 ? void 0 : image.props) === null || _e === void 0 ? void 0 : _e.title) || "", disableLazy: true }) }), _jsxs(Box, { sx: { textAlign: "center" }, children: [_jsx(Typography, { variant: "h1", sx: { mt: 4 }, children: title }), dayjs(publishedDate).isValid() && (_jsx(Typography, { sx: {
                                        fontStyle: "italic",
                                        fontWeight: 400,
                                        fontSize: "1.143rem",
                                        lineHeight: "1.429rem",
                                        color: "colors.bleu_clair",
                                        mb: dynamicPart ? undefined : 2,
                                    }, children: getDateFromTimestamp(publishedDate, "DD/MM/YYYY") }))] }), dynamicPart, mailto] }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(ItemsCard, { itemsTitle: "Offres d'emploi ARPAVIE", recentTitle: "Offres r\u00E9centes", itemsText: itemsText, recentItems: jobOffers, tags: allTags, onClickTag: filterTags }) })] }) }));
};
export default JobOfferTemplate;
