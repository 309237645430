import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Paper, Typography } from "@mui/material";
import EstablishmentAddressInline from "components/EstablishmentAddressInline";
var AgencyEstablishmentCard = function (_a) {
    var establishment = _a.establishment;
    var name = establishment.name, address = establishment.address;
    return (_jsx(Paper, { sx: { borderRadius: 1, position: "relative" }, tabIndex: 0, children: _jsxs(Box, { sx: {
                pt: 2,
                px: 2,
                pb: 3.5,
                minHeight: 115,
                position: "relative",
            }, children: [_jsx(Typography, { variant: "h5", sx: {
                        mb: 2.5,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "#26348B",
                        fontSize: "1.134rem",
                        fontWeight: 600,
                        lineHeight: "1.382rem",
                    }, title: name, children: name }), _jsx(Typography, { variant: "body1", sx: {
                        fontSize: "1.142rem",
                        fontWeight: 400,
                        lineHeight: "1.571rem",
                        fontFamily: "Montserrat",
                    }, children: _jsx(EstablishmentAddressInline, { address: address }) })] }) }));
};
export default AgencyEstablishmentCard;
