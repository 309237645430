export var getAllCookiesName = function () {
    var _a;
    return ((_a = document === null || document === void 0 ? void 0 : document.cookie) === null || _a === void 0 ? void 0 : _a.split("; ").map(function (c) { return c.split("=")[0]; }).filter(function (n) { return !!n; })) || [];
};
export var isCookieExist = function (cookie) {
    return !!(document === null || document === void 0 ? void 0 : document.cookie.split("; ").find(function (row) { return row.startsWith("".concat(cookie, "=")); }));
};
export var deleteCookie = function (name) {
    document.cookie = "".concat(name, "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT");
};
