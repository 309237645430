var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Button, Grid } from "@mui/material";
import CustomPopper from "components/CustomPopper";
var SearchLabel = function (props) {
    var label = props.label, value = props.value, content = props.content, disabled = props.disabled, PopperProps = props.PopperProps;
    var _a = React.useState(false), openPopper = _a[0], setOpenPopper = _a[1];
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var handleClose = React.useCallback(function () {
        setOpenPopper(false);
    }, []);
    return (_jsxs(Grid, { item: true, xs: 12, sm: 6, md: "auto", sx: { position: "relative" }, children: [_jsx(Button, { variant: "label", sx: {
                    borderColor: value ? "colors.recherche_fonce" : "colors.recherche_clair",
                    fontWeight: value ? 700 : 500,
                }, disabled: disabled, onClick: function (event) {
                    setOpenPopper(true);
                    setAnchorEl(event.currentTarget);
                }, endIcon: typeof value === "number" && value > 0 ? value : "", children: label }), _jsx(Box, __assign({ placement: "bottom-start" }, PopperProps, { component: CustomPopper, onClose: handleClose, open: openPopper, anchorEl: anchorEl, children: content }))] }));
};
export default SearchLabel;
