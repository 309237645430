var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { styled } from "@mui/system";
import { HashLink } from "react-router-hash-link";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import PageVersionContext from "cms/components/PageVersionContext";
import Icon from "cms/editableComponents/Icon";
import { Templates } from "types/templates.enum";
var BreadcrumbIcon = styled(Icon)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            color: theme.palette.colors.cyan
        },
        _b[theme.breakpoints.down("md")] = {
            marginRight: "10px",
        },
        _b);
});
var BreadcrumbItem = function (props) {
    var item = props.item, others = __rest(props, ["item"]);
    var path = typeof sessionStorage !== "undefined" && item.fullPath === "/residence" && sessionStorage.getItem("queryParams")
        ? "".concat(item.fullPath).concat(sessionStorage.getItem("queryParams"))
        : item.fullPath;
    return (_jsx(Link, __assign({ component: HashLink, to: path || "/", title: item.title }, others, { children: item.title })));
};
var Breadcrumb = function () {
    var currentPageVersion = useContext(PageVersionContext).currentPageVersion;
    var template = (currentPageVersion || {}).template;
    var _a = (currentPageVersion || {}).breadcrumb, breadcrumb = _a === void 0 ? [] : _a;
    var parentPage = React.useMemo(function () {
        return breadcrumb && breadcrumb.length > 1 && breadcrumb[breadcrumb.length - 2];
    }, [breadcrumb]);
    return (breadcrumb &&
        breadcrumb.length > 1 && (_jsxs(Box, { sx: {
            px: 2,
            pt: 2,
            maxWidth: template === Templates.ESTABLISHMENT
                ? {
                    xs: "calc(100% - 200px)",
                    sm: "calc(100% - 300px)",
                    md: "calc(100% - 340px)",
                }
                : "none",
        }, children: [_jsx(Breadcrumbs, { "aria-label": "Fil d'ariane", sx: { display: { xs: "flex", md: "none" }, mr: "0.714rem" }, children: _jsxs(Box, { children: [_jsx(BreadcrumbIcon, { icon: "caret-left", type: "fas" }), _jsx(BreadcrumbItem, { item: parentPage })] }) }), _jsx(Breadcrumbs, { maxItems: 3, itemsAfterCollapse: 2, "aria-label": "Fil d'ariane", separator: _jsx(BreadcrumbIcon, { icon: "caret-right", type: "fas" }), sx: { display: { xs: "none", md: "flex" } }, children: breadcrumb.map(function (breadcrumbItem, index) {
                    return index !== breadcrumb.length - 1 ? (_jsx(BreadcrumbItem, { item: breadcrumbItem }, breadcrumbItem.pageId)) : (_jsx(Typography, { title: breadcrumbItem.title, sx: { color: "colors.bleu_clair" }, children: breadcrumbItem.title }, breadcrumbItem.pageId));
                }) })] })));
};
Breadcrumb.propTypes = {};
Breadcrumb.defaultProps = {};
export default Breadcrumb;
