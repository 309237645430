import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch, Typography, } from "@mui/material";
var CookiesTable = function (_a) {
    var cookiesRows = _a.cookiesRows, checked = _a.checked, _b = _a.onChange, onChange = _b === void 0 ? function () { return undefined; } : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.description, description = _d === void 0 ? null : _d, subtitle = _a.subtitle, switchLabel = _a.switchLabel;
    var TableHeadCells = ["Nom de cookies", "Finalité", "Durée de conservation"];
    var _e = useState(""), switchStateLabel = _e[0], setSwitchStateLabel = _e[1];
    var _f = useState(9), labelLeftPosition = _f[0], setLabelLeftPosition = _f[1];
    useEffect(function () {
        if (disabled) {
            setSwitchStateLabel("Requis");
            setLabelLeftPosition(11);
        }
        else if (checked) {
            setSwitchStateLabel("Activés");
            setLabelLeftPosition(9);
        }
        else {
            setSwitchStateLabel("Désactivés");
            setLabelLeftPosition(3);
        }
    }, [checked, disabled]);
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { container: true, alignItems: "center", wrap: "nowrap", children: [_jsxs(Grid, { item: true, xs: "auto", md: 1, sx: { position: "relative" }, children: [_jsx(Switch, { checked: checked, onChange: onChange, disabled: disabled }), _jsx(FormHelperText, { sx: { position: "absolute", bottom: -10, left: labelLeftPosition }, children: switchLabel || switchStateLabel })] }), _jsx(Typography, { component: "span", variant: "h6", children: subtitle })] }), description && (_jsx(Grid, { container: true, justifyContent: "flex-end", sx: { mt: "12px" }, children: _jsx(Grid, { item: true, xs: 12, md: 11, children: _jsx(Typography, { component: "span", variant: "body2", children: description }) }) })), cookiesRows && (_jsx(Grid, { container: true, justifyContent: "flex-end", children: _jsx(Grid, { item: true, xs: 12, md: 11, children: _jsx(TableContainer, { children: _jsxs(Table, { "aria-label": "Description des cookies", children: [_jsx(TableHead, { children: _jsx(TableRow, { children: TableHeadCells.map(function (cell) { return (_jsx(TableCell, { children: cell }, cell)); }) }) }), _jsx(TableBody, { children: cookiesRows.map(function (row) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: row.name }), _jsx(TableCell, { children: row.finality }), _jsx(TableCell, { children: row.conservation })] }, row.id)); }) })] }) }) }) }))] }));
};
export default CookiesTable;
