import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Icon from "cms/editableComponents/Icon";

const InfoBlock = (props) => {
  const { icon, text } = props;

  return (
    <Grid container>
      {icon && (
        <Grid
          item
          sx={(theme) => ({
            p: 2,
            bgcolor: "primary.main",
            color: theme.palette.getContrastText(theme.palette.primary.main),
            fontSize: "1.7rem",
            display: { xs: "none", lg: "flex" },
            alignItems: "center",
          })}
        >
          <Icon {...icon} title="Information" />
        </Grid>
      )}
      <Grid item xs sx={{ bgcolor: "#fff", p: { md: 4 } }}>
        {text}
      </Grid>
    </Grid>
  );
};

InfoBlock.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  text: PropTypes.node,
};

InfoBlock.defaultProps = {
  icon: null,
  text: null,
};

export default InfoBlock;
