import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/system/Box";
import Navigation from "Layout/Navigation";
import Footer from "Layout/Footer";
import CookiesBanner from "Layout/CookiesBanner";
import { CookiesContextProvider } from "components/CookiesContext";
import Breadcrumb from "Layout/Breadcrumb";
import { MatomoContextProvider } from "cms/components/MatomoContextProvider";
var Layout = function (props) {
    var children = props.children, bo = props.bo;
    var _a = useState(), scrollableElement = _a[0], setScrollableElement = _a[1];
    var scrollableElementRef = useRef();
    useEffect(function () {
        if (scrollableElementRef.current) {
            setScrollableElement(scrollableElementRef.current);
        }
    }, [scrollableElementRef]);
    return (_jsx(MatomoContextProvider, { disabled: bo, children: _jsxs(CookiesContextProvider, { children: [_jsx(CssBaseline, {}), _jsxs(Box, { sx: {
                        fontFamily: "Montserrat",
                        height: "100%",
                        bgcolor: "#FFFFFF",
                        display: "flex",
                        flex: "1 1 auto",
                        flexDirection: "column",
                    }, children: [_jsx(Navigation, { scrollableElement: scrollableElement, bo: bo }), _jsx(Box, { sx: { flex: "1 0 auto", position: "relative" }, children: _jsxs(Box, { ref: scrollableElementRef, sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    overflow: "auto",
                                }, children: [_jsxs(Container, { disableGutters: true, sx: { flex: "1 0 auto", pt: { xs: 2, lg: 4.5 } }, children: [_jsx(Breadcrumb, {}), children] }), _jsx(Footer, {})] }) })] }), _jsx(CookiesBanner, { bo: bo })] }) }));
};
export default Layout;
