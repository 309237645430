import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography, Tooltip, Grid } from "@mui/material";
import Tag from "components/Tag";
var ItemTags = function (_a) {
    var tags = _a.tags, _b = _a.direction, direction = _b === void 0 ? "horizontal" : _b, onClickTag = _a.onClickTag, limit = _a.limit, _c = _a.selectedTags, selectedTags = _c === void 0 ? [] : _c;
    var tagsToDisplay = React.useMemo(function () {
        if (!limit || tags.length <= limit) {
            return tags;
        }
        return tags.slice(0, limit - 1);
    }, [limit, tags]);
    var remainingTags = tags.filter(function (tag) { return !tagsToDisplay.includes(tag); });
    if (tagsToDisplay.length === 0) {
        return null;
    }
    var onKeyPressTag = function (e, tag) {
        if (e.key === "Enter" && onClickTag) {
            onClickTag(tag);
        }
    };
    var handleClickTag = function (e, tag) {
        if (onClickTag) {
            onClickTag(tag);
        }
    };
    return (_jsxs(Grid, { container: true, spacing: 1, direction: direction === "horizontal" ? "row" : "column", children: [tagsToDisplay.map(function (tag) { return (_jsx(Grid, { item: true, children: _jsx(Tag, { tabIndex: onClickTag ? 0 : -1, role: "button", onKeyDown: function (e) { return onKeyPressTag(e, tag); }, onClick: function (e) { return handleClickTag(e, tag); }, isSelected: selectedTags.includes(tag), children: tag }) }, tag)); }), remainingTags.length > 0 && (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: _jsx(_Fragment, { children: remainingTags.map(function (tag) { return (_jsx(Typography, { variant: "caption", display: "block", children: tag }, tag)); }) }), placement: "bottom-end", children: _jsx(Tag, { children: "..." }) }) }))] }));
};
export default ItemTags;
