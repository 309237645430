import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOMServer from "react-dom/server";
import Box from "@mui/material/Box";
import EstablishmentCard from "components/EstablishmentCard";
import AgencyEstablishmentCard from "components/AgencyEstablishmentCard";
import GoogleMap from "components/GoogleMap";
import GoogleMapMarker from "components/GoogleMapMarker";
import { getEstablishmentIconMarker } from "utils/establishmentUtils";
var GoogleMapEstablishments = function (_a) {
    var _b = _a.establishments, establishments = _b === void 0 ? [] : _b, agencies = _a.agencies, _c = _a.height, height = _c === void 0 ? "700px" : _c, centerProps = _a.center, _d = _a.zoom, zoom = _d === void 0 ? 13 : _d, disableLink = _a.disableLink;
    var currentEstablishments = agencies || establishments;
    var center = React.useMemo(function () {
        return centerProps ||
            currentEstablishments.reduce(function (center, _a) {
                var latitude = _a.latitude, longitude = _a.longitude;
                return {
                    lat: center.lat + Number(latitude) / currentEstablishments.length,
                    lng: center.lng + Number(longitude) / currentEstablishments.length,
                };
            }, { lat: 0, lng: 0 });
    }, [centerProps, currentEstablishments]);
    return (_jsx(GoogleMap, { center: center, zoom: zoom, style: { height: height, width: "100%" }, children: currentEstablishments.map(function (establishment, index) {
            var latitude = establishment.latitude, longitude = establishment.longitude;
            var infoWindowContent = ReactDOMServer.renderToString(_jsx(Box, { sx: { width: { xs: "100%", sm: 400 } }, children: agencies ? (_jsx(AgencyEstablishmentCard, { establishment: establishment })) : (_jsx(EstablishmentCard
                // @ts-ignore
                , { 
                    // @ts-ignore
                    establishment: establishment, isInMap: true, disableLink: disableLink })) }));
            return (_jsx(GoogleMapMarker, { position: { lat: Number(latitude), lng: Number(longitude) }, infoWindow: {
                    content: infoWindowContent,
                    defaultOpen: currentEstablishments.length === 1,
                }, 
                // @ts-ignore
                icon: agencies ? "/EHPAD.png" : getEstablishmentIconMarker(establishment) }, latitude + "," + longitude));
        }) }));
};
export default GoogleMapEstablishments;
