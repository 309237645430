import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import SiteService from "cms/services/SiteService";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";

const SiteContext = createContext();
export default SiteContext;

export const SiteContextProvider = (props) => {
  const { site, children } = props;
  const { data } = useQuery("site", SiteService.get);

  // il est possible de passer un site props, cas du back office
  const currentSite = (typeof data === "object" && data.id && data) || site || {};
  if (currentSite && currentSite.contents) {
    const contentsProps = generateTemplatePropsFromContents(currentSite.contents);
    const { settings } = contentsProps || {};
    currentSite.settings = settings;
  }

  return <SiteContext.Provider value={currentSite}>{children}</SiteContext.Provider>;
};

SiteContextProvider.propTypes = {
  site: PropTypes.shape(),
  children: PropTypes.node,
};

SiteContextProvider.defaultProps = {
  site: null,
  children: null,
};
