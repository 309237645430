import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from "react";
import { Grid, Box, Link, Typography, Button } from "@mui/material";
import CookiesContext from "components/CookiesContext";
import CookiesModal from "components/CookiesModal";
import { HashLink } from "react-router-hash-link";
var CookiesBanner = function (_a) {
    var _b = _a.bo, bo = _b === void 0 ? false : _b;
    var _c = useContext(CookiesContext), handleCookiesModalContext = _c.handleCookiesModal, openCookiesModal = _c.openCookiesModal, isDisabledBanner = _c.isDisabledBanner, disabledBanner = _c.disabledBanner;
    var handleCookiesModal = function () {
        if (openCookiesModal && !isDisabledBanner) {
            disabledBanner();
        }
        handleCookiesModalContext();
    };
    var handleRefuseAll = function () {
        disabledBanner();
    };
    var handleAcceptAll = function () {
        disabledBanner();
    };
    return (_jsxs(_Fragment, { children: [!bo && !isDisabledBanner && (_jsx(Box, { sx: {
                    bgcolor: "colors.blanc",
                    position: "fixed",
                    borderTop: "2px solid",
                    borderColor: "colors.cyan_fonce",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: "550",
                    "@media print": {
                        display: "none",
                    },
                }, children: _jsxs(Box, { sx: {
                        position: "relative",
                        m: "0 auto",
                        maxWidth: "1100px",
                        px: { xs: 1.5, sm: 2, md: 3 },
                        py: { xs: 1.5, sm: 2, md: 3 },
                    }, children: [_jsx(Typography, { component: "span", variant: "h3", children: "\u00C0 propos des cookies d'Arpavie" }), _jsxs(Box, { sx: { my: { xs: 0.75, sm: 2 } }, children: [_jsx(Typography, { sx: { xs: { fontSize: "1rem" }, md: { fontSize: "inherit" } }, children: "Ce site web utilise exclusivement des cookies n\u00E9cessaires au fonctionnement du site et des cookies de mesure d'audience." }), _jsx(Typography, { sx: { xs: { fontSize: "1rem" }, md: { fontSize: "inherit" } }, children: "Ils sont anonymis\u00E9s et ne contiennent aucune de vos donn\u00E9es personnelles." }), _jsxs(Typography, { sx: { xs: { fontSize: "1rem" }, md: { fontSize: "inherit" } }, children: ["Nous conservons votre choix pendant 13 mois. Vous pouvez changer ce choix \u00E0 tout moment et pouvez consulter notre", " ", _jsx(Link, { component: HashLink, to: "/donnees-personnelles-et-cookies", children: "politique de confidentialit\u00E9\u00A0" }), "."] })] }), _jsxs(Grid, { container: true, textAlign: "center", spacing: { xs: 0.5, sm: 2 }, sx: { justifyContent: { xs: "center", sm: "right" } }, children: [_jsx(Grid, { item: true, children: _jsx(Button, { onClick: handleCookiesModal, children: "Personnaliser" }) }), _jsx(Grid, { item: true, children: _jsx(Button, { onClick: handleRefuseAll, variant: "contained", children: "Tout refuser" }) }), _jsx(Grid, { item: true, children: _jsx(Button, { variant: "contained", onClick: handleAcceptAll, children: "Tout accepter" }) })] })] }) })), _jsx(CookiesModal, { handleCookiesModal: handleCookiesModal })] }));
};
export default CookiesBanner;
