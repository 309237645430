import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export const TimelineStep = (props) => {
  const { id, title, content } = props;
  return (
    <Box
      sx={{
        ml: 1,
        borderLeft: "4px solid",
        borderColor: "secondary.dark",
        position: "relative",
        pl: 3,
        pb: 4,
      }}
      key={id}
    >
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "-12px",
          top: "-1px",
          display: "block",
          height: "20px",
          width: "20px",
          bgcolor: "secondary.dark",
          color: "white",
          borderRadius: "50%",
        }}
      />
      <Box
        sx={{
          "& .MuiTypography-root": {
            fontWeight: "fontWeightBold",
          },
          pb: 0.5,
        }}
      >
        {title}
      </Box>
      {content}
    </Box>
  );
};

TimelineStep.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.node,
  content: PropTypes.node,
};

TimelineStep.defaultProps = {
  title: null,
  content: null,
};

const Timeline = (props) => {
  const { steps, ...others } = props;

  return <div {...others}>{steps}</div>;
};

Timeline.propTypes = {
  steps: PropTypes.node,
};

Timeline.defaultProps = {
  steps: null,
};

export default Timeline;
