var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";
import contentsTypes from "cms/enums/contentsTypes";
import Image, { ImageContainer } from "cms/editableComponents/Image";
import Link from "cms/editableComponents/Link";
import EstablishmentAddressInline from "components/EstablishmentAddressInline";
import EstablishmentTags from "components/EstablishmentTags";
import { getEstablishmentTypeLabel } from "utils/establishmentUtils";
import { EstablishmentPriceTimeUnit } from "types/establishment/establishment-price-time-unit.enum";
var defaultImage = "/default-image-establishment.jpg";
var EstablishmentCard = function (_a) {
    var establishment = _a.establishment, _b = _a.imageRatio, imageRatio = _b === void 0 ? 90 : _b, isInMap = _a.isInMap, disableLink = _a.disableLink, others = __rest(_a, ["establishment", "imageRatio", "isInMap", "disableLink"]);
    var history = useHistory();
    var name = establishment.name, type = establishment.type, address = establishment.address, prices = establishment.prices, pageVersion = establishment.pageVersion;
    var fullPath = (pageVersion || {}).fullPath;
    var city = address.city;
    var image = React.useMemo(function () {
        var _a, _b, _c;
        if (pageVersion) {
            var _d = pageVersion.contents, contents = _d === void 0 ? [] : _d;
            var imagesGalleryContent = contents.find(function (content) { return content.type === contentsTypes.IMAGES_GALLERY; });
            if (imagesGalleryContent) {
                var imagesContent = (_a = imagesGalleryContent.children) === null || _a === void 0 ? void 0 : _a.find(function (content) { return content.type === contentsTypes.IMAGES; });
                if (imagesContent) {
                    var imageContent = (_b = imagesContent.children) === null || _b === void 0 ? void 0 : _b.find(function (content) { return content.type === contentsTypes.IMAGE_AS_FILE; });
                    if (imageContent) {
                        var fileContent = (_c = imageContent.children) === null || _c === void 0 ? void 0 : _c.find(function (content) { return content.type === contentsTypes.FILE_IMAGE; });
                        if (fileContent) {
                            try {
                                var file = JSON.parse(fileContent.value);
                                return file;
                            }
                            catch (e) { }
                        }
                    }
                }
            }
        }
        return defaultImage;
    }, [pageVersion]);
    var minPrice = (prices &&
        prices.length > 0 &&
        prices.reduce(function (min, price) {
            if (!min || price.price < min.price) {
                return price;
            }
            return min;
        })) ||
        null;
    var onKeyDown = function (e) {
        if (e.key === "Enter" && fullPath) {
            e.preventDefault();
            history.push(fullPath);
        }
    };
    var enableLinkMap = useMemo(function () { return isInMap && !disableLink; }, [isInMap, disableLink]);
    return (_jsxs(Paper, __assign({ sx: { borderRadius: 1, position: "relative", height: "100%" }, tabIndex: 0, onKeyDown: onKeyDown, "aria-label": establishment.name }, others, { children: [!isInMap && (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: { xs: "none", lg: "flex" },
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            textAlign: "center",
                            bgcolor: "colors.bleu",
                            color: "#fff",
                            zIndex: 20,
                            opacity: 0,
                            transition: "opacity ease-in 250ms",
                            "&:hover": {
                                opacity: 1,
                            },
                        }, children: [_jsxs(Box, { children: [_jsx(Typography, { color: "inherit", variant: "h2", sx: { mb: 2 }, children: name }), _jsx(Typography, { color: "inherit", variant: "h3", children: "Maison de retraite" }), _jsx(Typography, { color: "inherit", variant: "h3", children: getEstablishmentTypeLabel(type) }), _jsx(Typography, { color: "inherit", variant: "h4", sx: { mt: 2 }, children: city })] }), _jsx(Box, { children: _jsx(Button
                                // @ts-ignore
                                , { 
                                    // @ts-ignore
                                    page: pageVersion, component: Link, tabIndex: -1, "aria-label": "Accéder à la fiche" + establishment.name, variant: "outlined", sx: {
                                        "&:hover, &:focus": {
                                            color: "colors.bleu",
                                            bgcolor: "colors.blanc",
                                            borderColor: "colors.bleu",
                                        },
                                    }, children: "Acc\u00E9der \u00E0 la fiche" }) })] }), _jsx(Box, { sx: {
                            position: "absolute",
                            top: 16,
                            right: 16,
                            left: 16,
                            display: "flex",
                            zIndex: 10,
                            justifyContent: "flex-end",
                            flexWrap: "wrap",
                        }, children: _jsx(EstablishmentTags, { establishment: establishment }) }), _jsx(ImageContainer, { ratio: imageRatio, children: _jsx(Image, { file: { url: image === null || image === void 0 ? void 0 : image.url }, alt: (image === null || image === void 0 ? void 0 : image.alt) || "", title: image === null || image === void 0 ? void 0 : image.title, disableLazy: true }) })] })), _jsxs(Box, { sx: {
                    px: 2,
                    pt: 2,
                    pb: { xs: enableLinkMap ? 7 : 4, lg: enableLinkMap ? 7 : 3.5 },
                    minHeight: enableLinkMap ? "none" : 210,
                }, children: [_jsx(Box, { sx: __assign({ mb: 2.5 }, ((!isInMap && {
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                        }) ||
                            {})), children: _jsx(Typography, { component: "span", variant: "h4", title: name, sx: {
                                color: "#26348B",
                                fontFamily: "Forum",
                                fontSize: "2.571rem",
                            }, children: name }) }), _jsx(Typography, { variant: "body1", sx: {
                            fontSize: "1.142rem",
                            fontWeight: 400,
                            lineHeight: "1.571rem",
                            fontFamily: "Montserrat",
                        }, children: _jsx(EstablishmentAddressInline, { address: address }) }), minPrice && (_jsxs(Typography, { variant: "caption", sx: { m: 0, fontFamily: "Montserrat", fontWeight: 400, fontSize: "0.75rem", lineHeight: "1.66rem" }, children: ["\u00E0 partir de ", minPrice.price, " \u20AC /", " ", minPrice.timeUnit === EstablishmentPriceTimeUnit.DAILY ? "jour / personne" : "mois"] })), !disableLink &&
                        fullPath &&
                        (isInMap ? (_jsx(Box, { component: "a", href: fullPath, "aria-label": "Accéder à la fiche" + establishment.name, sx: {
                                position: "absolute",
                                bottom: 14,
                                right: 8,
                                borderRadius: 25,
                                px: 1.875,
                                py: 0.875,
                                border: "1px solid",
                                borderColor: "#26348B",
                                bgcolor: "#fff",
                                color: "#26348B",
                                fontSize: "1.142rem",
                                fontWeight: 700,
                                fontFamily: "Montserrat",
                                textDecoration: "none",
                            }, children: "Acc\u00E9der \u00E0 la fiche" })) : (_jsx(Button, { variant: "outlined", 
                            // @ts-ignore
                            page: pageVersion, component: Link, tabIndex: -1, "aria-label": "Accéder à la fiche" + establishment.name, sx: { position: "absolute", bottom: 14, right: 8, display: { xs: "block", lg: "none" } }, children: "Acc\u00E9der \u00E0 la fiche" })))] })] })));
};
export default EstablishmentCard;
