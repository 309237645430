var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
var Tag = function (_a) {
    var Icon = _a.Icon, children = _a.children, _b = _a.sx, sx = _b === void 0 ? {} : _b, _c = _a.isSelected, isSelected = _c === void 0 ? false : _c, onClick = _a.onClick, others = __rest(_a, ["Icon", "children", "sx", "isSelected", "onClick"]);
    return (_jsxs(Box, __assign({}, others, { onClick: onClick, sx: __assign({ borderRadius: "5px", borderStyle: "solid", borderWidth: "1px", borderColor: isSelected ? "colors.blanc" : "colors.bleu_fonce", color: isSelected ? "colors.blanc" : "colors.bleu_fonce", bgcolor: isSelected ? "colors.bleu_fonce" : "colors.blanc", px: 2, py: 1, display: "inline-flex", alignItems: "center", cursor: onClick ? "pointer" : undefined }, sx), children: [Icon && (_jsx(Typography, { color: "inherit", sx: { fontSize: 20, mr: 2 }, children: Icon })), _jsx(Typography, { color: "inherit", variant: "subtitle1", children: children })] })));
};
export default Tag;
