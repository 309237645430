import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const Icon = (props) => {
  const { icon, type, color, background, sx, className, ...others } = props;

  return (
    <Box
      component="i"
      {...others}
      sx={{
        "&.fas, &.far, &.fal, &.fad, &.fab": {
          color: color || undefined,
          ...((background && {
            bgcolor: background,
            width: "2em",
            height: "2em",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }) ||
            {}),
          ...sx,
        },
      }}
      className={`${type} fa-${icon} ${className}`}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(["fas", "far", "fal", "fad", "fab"]),
  color: PropTypes.string,
  background: PropTypes.string,
  sx: PropTypes.object,
};

Icon.defaultProps = {
  icon: "",
  title: "",
  type: "fal",
  color: undefined,
  background: undefined,
  sx: {},
};

export default Icon;
