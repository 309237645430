import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import { summaryTitle as criteriaSummaryTitle } from "routes/Search/Search-CriteriaStep";
import { summaryTitle as locationSummaryTitle } from "routes/Search/Search-LocationStep";
import { summaryTitle as PriceSummaryTitle } from "routes/Search/Search-PriceStep";
import { summaryTitle as AdditionalCriteriaSummaryTitle } from "routes/Search/Search-AdditionalCriteriaStep";
import { useFeatureList } from "services/feature.service";
import { EstablishmentType } from "types/establishment/establishment-type.enum";
import { SearchParamsAvailability } from "types/search-params-availability.enum";
import { EstablishmentPriceType } from "types/establishment/establishment-price-type.enum";
import { getEstablishmentTypeLabel } from "utils/establishmentUtils";
import { getEstablishmentTypeFromSearchParams } from "utils/establishmentSearchUtils";
var SummaryBlock = function (_a) {
    var index = _a.index, title = _a.title, _b = _a.active, active = _b === void 0 ? false : _b, onClickEdit = _a.onClickEdit, children = _a.children;
    return (_jsxs(Grid, { container: true, direction: "column", children: [_jsxs(Grid, { item: true, container: true, alignItems: "center", wrap: "nowrap", sx: { pt: "30px" }, children: [_jsx(Typography, { sx: {
                            bgcolor: "colors.recherche_clair",
                            px: 2.5,
                            pt: 1.625,
                            pb: 1.375,
                            borderRadius: "30px",
                            mr: 1.75,
                            fontWeight: 700,
                            opacity: active ? 1 : 0.5,
                            display: { xs: "none", md: "block" },
                        }, children: index }), _jsx(Typography, { variant: "h2", sx: { opacity: active ? 1 : 0.5 }, children: title }), onClickEdit && _jsx(EditIcon, { onClick: onClickEdit, sx: { ml: "20px", color: "colors.cyan", cursor: "pointer" } })] }), _jsx(Box, { sx: { px: { xs: 0, sm: "59px", lg: 0, xl: "59px" } }, children: children })] }));
};
var SummaryValue = function (_a) {
    var label = _a.label, value = _a.value;
    return (_jsxs(Grid, { item: true, container: true, justifyContent: "space-between", alignItems: "center", sx: { pt: "11px" }, children: [_jsx(Typography, { variant: "body1", children: label }), _jsx(Typography, { variant: "subtitle1", children: value || "--" })] }));
};
var Summary = function (props) {
    var form = props.form, stepIndex = props.stepIndex, onClickEditStep = props.onClickEditStep;
    var availability = form.availability, city = form.city, radius = form.radius, budgetMin = form.budgetMin, budgetMax = form.budgetMax, specificTypes = form.specificTypes, establishmentPrices = form.establishmentPrices, proximityElements = form.proximityElements, PMR = form.PMR;
    var establishmentType = React.useMemo(function () {
        var type = getEstablishmentTypeFromSearchParams(form);
        return type && getEstablishmentTypeLabel(type);
    }, [form]);
    var allFeatures = useFeatureList({
        params: {
            per_page: 200,
        },
    }).data;
    return (_jsxs(Grid, { container: true, direction: "column", sx: { flex: 1 }, children: [_jsx(Grid, { item: true, children: _jsxs(SummaryBlock, { index: 1, title: criteriaSummaryTitle, active: true, onClickEdit: stepIndex > 1 ? onClickEditStep(1) : undefined, children: [_jsx(SummaryValue, { label: "Disponibilit\u00E9", value: SearchParamsAvailability[availability] }), _jsx(SummaryValue, { label: "Type d'h\u00E9bergement", value: establishmentType })] }) }), _jsx(Grid, { item: true, children: _jsxs(SummaryBlock, { index: 2, title: locationSummaryTitle, active: stepIndex >= 2, onClickEdit: stepIndex > 2 ? onClickEditStep(2) : undefined, children: [_jsx(SummaryValue, { label: "Localisation", value: city }), _jsx(SummaryValue, { label: "Rayon", value: city ? "".concat(radius, " kms") : undefined })] }) }), _jsx(Grid, { item: true, children: _jsxs(SummaryBlock, { index: 3, title: AdditionalCriteriaSummaryTitle, active: stepIndex >= 3, onClickEdit: stepIndex > 3 ? onClickEditStep(3) : undefined, children: [getEstablishmentTypeFromSearchParams(form) === EstablishmentType.EHPAD && specificTypes.length > 0 && (_jsx(SummaryValue, { label: "Prise en charge sp\u00E9cifique", value: specificTypes.map(function (type) { var _a, _b; return (_b = (_a = allFeatures === null || allFeatures === void 0 ? void 0 : allFeatures.data) === null || _a === void 0 ? void 0 : _a.find(function (el) { return el.id === type; })) === null || _b === void 0 ? void 0 : _b.name; }).join(", ") })), getEstablishmentTypeFromSearchParams(form) === EstablishmentType.RA && establishmentPrices.length > 0 && (_jsx(SummaryValue, { label: "Taille recherch\u00E9e", value: establishmentPrices.map(function (type) { return EstablishmentPriceType[type]; }).join(", ") })), proximityElements.length > 0 && (_jsx(SummaryValue, { label: "\u00C9l\u00E9ments \u00E0 proximit\u00E9", value: proximityElements
                                .map(function (element) { var _a, _b; return (_b = (_a = allFeatures === null || allFeatures === void 0 ? void 0 : allFeatures.data) === null || _a === void 0 ? void 0 : _a.find(function (el) { return el.id === element; })) === null || _b === void 0 ? void 0 : _b.name; })
                                .join(", ") })), PMR && _jsx(SummaryValue, { label: "Adapt\u00E9es Personnes \u00E0 Mobilit\u00E9 R\u00E9duite", value: PMR ? "Oui" : undefined }), specificTypes.length === 0 && proximityElements.length === 0 && establishmentPrices.length === 0 && !PMR && (_jsx(Typography, { variant: "body1", sx: { pt: "11px" }, children: "Aucun crit\u00E8re s\u00E9lectionn\u00E9" }))] }) }), _jsx(Grid, { item: true, children: _jsx(SummaryBlock, { index: 4, title: PriceSummaryTitle, active: stepIndex >= 4, children: _jsx(SummaryValue, { label: "Budget mensuel", value: budgetMax !== "0" ? "".concat(budgetMin, " - ").concat(budgetMax, " \u20AC") : "--" }) }) })] }));
};
export default Summary;
