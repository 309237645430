import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import MuiLink from "@mui/material/Link";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ExpansionText = (props) => {
  const { reducedLabel, expandedLabel, text, defaultExpanded, ...others } = props;

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleClickExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const Icon = isExpanded ? ExpandLess : ExpandMore;

  return (
    <div {...others}>
      {isExpanded ? text : ""}
      <MuiLink component="span" underline="none" onClick={handleClickExpand}>
        {isExpanded ? expandedLabel : reducedLabel}
        <Icon
          sx={{ ml: 0.5, verticalAlign: "middle", fontSize: "1.2em" }}
          title={isExpanded ? expandedLabel : reducedLabel}
        />
      </MuiLink>
    </div>
  );
};

ExpansionText.propTypes = {
  reducedLabel: PropTypes.string.isRequired,
  expandedLabel: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
};

ExpansionText.defaultProps = {
  defaultExpanded: false,
};

export default ExpansionText;
