import React, { Fragment, createRef, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Block from "cms/components/Block";
import Link from "cms/editableComponents/Link";
import wrappable from "cms/utils/wrappable";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const GlossaryItem = wrappable((props) => {
  const { word, description } = props;

  return (
    <Block>
      <Typography variant="h3" sx={{ m: 0 }}>
        {word}
      </Typography>
      <Box sx={{ mt: 0.5 }}>{description}</Box>
    </Block>
  );
});

const Glossary = (props) => {
  const { location } = props;
  let { items } = props;
  const [lettersTop, setLettersTop] = useState(null);
  const [letters, setLetters] = useState([]);
  const { hash } = location;

  if (items && !Array.isArray(items)) {
    items = [items];
  }
  const filteredItems = items.filter((item) => item && item.word);

  filteredItems.sort((a, b) => a.word.localeCompare(b.word));

  const groupedItems = {};

  filteredItems.forEach((item) => {
    const { word } = item;
    const firstLetter = word.toUpperCase().split("")[0];
    groupedItems[firstLetter] = groupedItems[firstLetter] || [];
    groupedItems[firstLetter].push(item);
  });

  const lettersRef = createRef();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (isMobile) {
      setLettersTop((window.innerHeight - lettersRef.current.getBoundingClientRect().height) / 2);
    }
  }, [lettersRef, isMobile]);

  useEffect(() => {
    // Si trop de lettres, alors on remplace l'excedent par "..." au milieu de la liste
    // qui pointera vers la première lettre retirée
    const allLetters = Object.keys(groupedItems).map((item) => ({ letter: item, hash: item }));
    if (isMobile) {
      const maxLetters = 15;
      const nbLetters = Object.keys(groupedItems).length;
      if (nbLetters > maxLetters) {
        const nbSubstract = nbLetters - maxLetters;
        const indexStartSubstract = Math.round(maxLetters / 2);
        allLetters.splice(indexStartSubstract, nbSubstract, {
          letter: "...",
          hash: allLetters[indexStartSubstract].hash,
        });
      }
    }
    setLetters(allLetters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, isMobile]);

  return (
    <Fragment>
      <Box
        sx={{
          display: { xs: lettersTop ? "flex" : "none", lg: "flex" },
          flexDirection: { xs: "column", lg: "row" },
          alignItems: { xs: "center", lg: "flex-start" },
          fontSize: { xs: "1.2rem", lg: "1.42rem" },
          fontWeight: { xs: 400, lg: 600 },
          position: { xs: "fixed", md: "-webkit-sticky", lg: "sticky" },
          bgcolor: { xs: "primary.main", lg: "colors.blanc" },
          top: { xs: lettersTop ? lettersTop : "initial", lg: 0 },
          mb: { xs: 0, lg: 1 },
          py: { xs: 1, lg: 2 },
          right: { xs: 0, lg: "unset" },
        }}
        ref={lettersRef}
      >
        {letters.map((item) => (
          <Link
            key={item.letter}
            smooth
            url={`${location.pathname}#${item.hash}`}
            sx={{
              textTransform: "uppercase",
              flex: 1,
              maxWidth: "50px",
              cursor: "pointer",
              color: { xs: "common.white", lg: hash === `#${item.hash}` ? "secondary.main" : "inherit" },
              lineHeight: { md: "2rem" },
              fontSize: { md: `#${item.hash}` ? "2rem" : "inherit" },
              px: { xs: 0.5, lg: 0 },
              py: { xs: 0.25, lg: 0 },
              "&:hover": {
                color: "secondary.main",
              },
            }}
          >
            {item.letter}
          </Link>
        ))}
      </Box>
      <Box sx={{ pt: { xs: 1, lg: 0 } }}>
        {Object.keys(groupedItems).map((letter) => (
          <Block sx={{ pt: { xs: 1, lg: 0 } }} id={letter} key={letter}>
            <Box
              sx={{
                color: "primary[70]",
                pb: 2,
                borderBottom: "1px solid",
                borderColor: "secondary.main",
                "& h2": {
                  m: 0,
                  px: { xs: 2, lg: 0 },
                },
              }}
            >
              <Typography variant="h2">{letter}</Typography>
            </Box>
            {groupedItems[letter].map((item) => (
              <GlossaryItem key={item.id} {...item} />
            ))}
          </Block>
        ))}
      </Box>
    </Fragment>
  );
};

Glossary.propTypes = {
  location: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()),
};

Glossary.defaultProps = {
  items: [],
};

export default withRouter(Glossary);
